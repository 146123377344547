import {
  makeStyles,
  styled,
  Tabs,
} from '@material-ui/core';

export const tabStyles = makeStyles(() => ({
  subTab: {
    '& .MuiTab-root': {
      fontWeight: 'bold !important',
      fontSize: '14px !important',
    },
    '& .Mui-selected': {
      fontWeight: 'bold',
    },
  },
}));

export const CustomTabs = styled(Tabs)(({ theme }) => ({
  '& .MuiTabs-flexContainer': {
    borderBottom: '0px',
  },
  '& .MuiTab-root': {
    textTransform: 'none',
    height: '48px',
    color: theme.palette.primary.main,
    fontSize: '16px',
    fontWeight: 'normal',
  },
  '& .Mui-selected': {
    textTransform: 'none',
    height: '48px',
    color: theme.palette.text.primary,
  },
  '& .MuiTabs-indicator': {
    backgroundColor: theme.palette.text.primary,
    height: '6px',
    '&::before': {
      content: '""',
      height: '10px',
      width: '10px',
      backgroundColor: theme.palette.text.primary,
      position: 'absolute',
      right: '50%',
      top: '-5px',
      transform: 'rotate(45deg)',
    },
  },
}));
