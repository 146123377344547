import React, { useContext, useState } from 'react';
import {
  Avatar,
  ButtonBase,
  ClickAwayListener,
  Grid,
  Grow,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  Typography,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { getTranslatorSchema, logout } from '../../../utils/tools';
import { SetLoginState } from '../../../types/tools';
import { AppContext } from '../../../context/App';
import userMenuStyles from './styles';

interface UserMenuProps {
  setLoginState: SetLoginState,
}

function UserMenu(props: UserMenuProps): JSX.Element {
  const { state } = useContext(AppContext);
  const styles = userMenuStyles();
  const { setLoginState } = props;
  const [open, setOpen] = useState<boolean>(false);
  const anchorRef = React.useRef<HTMLButtonElement>(null);
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down('sm'));
  const handleOnClick: React.MouseEventHandler<HTMLButtonElement> = (e) => {
    e.preventDefault();
    setOpen(true);
  };
  const { t } = useTranslation();
  const translator = getTranslatorSchema('userMenu', t);

  return (
    <>
      <ButtonBase data-testid="user-menu" ref={anchorRef} onClick={handleOnClick} className={styles.userMenuContainer}>
        <Grid container justify="center" alignItems="center">
          <Grid item xs={!isSmall ? 2 : undefined}>
            <Avatar />
          </Grid>
          {!isSmall && (
            <Grid item xs={7} className="ml-2">
              <Grid container item xs={12}>
                <Typography variant="body1" noWrap>
                  {state.me.firstName}
                  {' '}
                  {state.me.lastName}
                </Typography>
              </Grid>
              <Grid container item xs={12}>
                <Typography variant="caption" noWrap>{state.me.companyName}</Typography>
              </Grid>
            </Grid>
          )}
          {!isSmall && <i className={styles.downArrow} />}
        </Grid>
      </ButtonBase>
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        className={styles.menuList}
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...TransitionProps}
            style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
          >
            <Paper variant="outlined" data-testid="userMenu-dropdown">
              <ClickAwayListener onClickAway={() => { setOpen(false); }}>
                <MenuList
                  autoFocusItem={open}
                  id="menu-list-grow"
                >
                  <MenuItem
                    data-testid="userMenu-logout-button"
                    onClick={() => { logout(setLoginState); }}
                  >
                    {translator('logout')}
                  </MenuItem>
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
}

export default UserMenu;
