import { Theme } from '@material-ui/core';

const styleIterations = 30;

export const createMargin: (theme: Theme) => Record<string, unknown> = (theme) => {
  const margin: Record<string, unknown> = {};
  for (let index = 1; index < styleIterations + 1; index += 1) {
    margin[`.mt-${index}`] = {
      marginTop: theme.spacing(index),
    };
    margin[`.mb-${index}`] = {
      marginBottom: theme.spacing(index),
    };
    margin[`.mr-${index}`] = {
      marginRight: theme.spacing(index),
    };
    margin[`.ml-${index}`] = {
      marginLeft: theme.spacing(index),
    };
    margin[`.mx-${index}`] = {
      marginLeft: theme.spacing(index),
      marginRight: theme.spacing(index),
    };
    margin[`.my-${index}`] = {
      marginBottom: theme.spacing(index),
      marginTop: theme.spacing(index),
    };
    margin[`.m-${index}`] = {
      marginRight: theme.spacing(index),
      marginLeft: theme.spacing(index),
      marginBottom: theme.spacing(index),
      marginTop: theme.spacing(index),
    };
  }

  return margin;
};

export const createPadding: (theme: Theme) => Record<string, unknown> = (theme) => {
  const padding: Record<string, unknown> = {};
  for (let index = 1; index < styleIterations + 1; index += 1) {
    padding[`.pt-${index}`] = {
      paddingTop: theme.spacing(index),
    };
    padding[`.pb-${index}`] = {
      paddingBottom: theme.spacing(index),
    };
    padding[`.pr-${index}`] = {
      paddingRight: theme.spacing(index),
    };
    padding[`.pl-${index}`] = {
      paddingLeft: theme.spacing(index),
    };
    padding[`.px-${index}`] = {
      paddingLeft: theme.spacing(index),
      paddingRight: theme.spacing(index),
    };
    padding[`.py-${index}`] = {
      paddingBottom: theme.spacing(index),
      paddingTop: theme.spacing(index),
    };
    padding[`.p-${index}`] = {
      paddingRight: theme.spacing(index),
      paddingLeft: theme.spacing(index),
      paddingBottom: theme.spacing(index),
      paddingTop: theme.spacing(index),
    };
  }

  return padding;
};

export default createMargin;
