import {
  Grid,
  styled,
  makeStyles,
} from '@material-ui/core';
import { GLOBAL_PADDING } from '../../styles/variables';

export const CustomFooterGrid = styled(Grid)(({ theme }) => ({
  paddingLeft: GLOBAL_PADDING,
  zIndex: 3,
  paddingRight: GLOBAL_PADDING,
  paddingTop: theme.spacing(1),
  paddingBottom: theme.spacing(1),
  background: theme.palette.divider,
  color: theme.palette.text.primary,
  [theme.breakpoints.down('md')]: {
    paddingLeft: theme.spacing(5),
    paddingRight: theme.spacing(5),
  },
}));

export const footerStyles = makeStyles((theme) => ({
  linksFooter: {
    borderTop: `1px solid ${theme.palette.text.primary}`,
    paddingTop: '0.2rem',
    '& a': {
      color: theme.palette.text.primary,
      textDecoration: 'underline',
    },
  },
  sectionMargin: {
    marginBottom: '0.3rem',
  },
}));
