import React from 'react';
import {
  Grid,
  Link,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { getTranslatorSchema } from '../../utils/tools';
import TabMenu from '../TabMenu';
import menuItems from './constants';
import UserMenu from './UserMenu';
import Logo from '../Logo';
import {
  CustomAppBar,
  useStyles,
} from './styles';
import routesDictionary from '../../routes/routesDict';
import { SetLoginState } from '../../types/tools';

const navigationHeader = [routesDictionary.report.split('/')[1], routesDictionary.comparison.split('/')[1]];

interface HeaderProps {
  setLoginState: SetLoginState,
}

function Header(props: HeaderProps): JSX.Element {
  const { setLoginState } = props;
  const { pathname } = useLocation();
  const classes = useStyles();
  const { t } = useTranslation();
  const translator = getTranslatorSchema('header', t);
  const [, firstPath] = pathname.split('/');
  const isNavigatorHeader = navigationHeader.includes(firstPath);

  return (
    <CustomAppBar position="fixed" elevation={0}>
      <Grid container justify="space-between" className={classes.headerContainer}>
        <Grid container item xs={8} lg={9} alignItems="center">
          <Grid container item xs={3} justify="flex-start" alignItems="center">
            <Logo header justify="flex-start" />
          </Grid>
          <Grid container item xs={9} className="h-100" alignItems={!isNavigatorHeader ? 'flex-end' : 'center'}>
            {
              !isNavigatorHeader
                ? <TabMenu items={menuItems(translator)} data-testid="header-navigation" />
                : <Link href={routesDictionary.dashboard} data-testid="go-back-dashboard">{translator('goBack')}</Link>
            }
          </Grid>
        </Grid>
        <Grid container item xs={4} lg={3} className={classes.userMenuContainer} justify="center">
          <UserMenu setLoginState={setLoginState} />
        </Grid>
      </Grid>
    </CustomAppBar>
  );
}

export default Header;
