import React from 'react';
import { Grid, GridJustification } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { ReactComponent as LogoSVG } from '../../assets/logo/SVG/TIIB_LogoFinal_colour.svg';
import logoStyles from './styles';
import routesDictionary from '../../routes/routesDict';

interface LogoProps {
  header?: boolean,
  justify?: GridJustification
}

const defaultProps = {
  header: false,
  justify: 'center',
};

function Logo(props: LogoProps): JSX.Element {
  const history = useHistory();
  const { header, justify } = props;
  const styles = logoStyles();

  return (
    <Grid
      container
      item
      justify={justify}
    >
      <LogoSVG
        onClick={() => {
          history.push(routesDictionary.dashboard);
        }}
        data-testid="logo"
        className={header ? styles.header : styles.default}
      />
    </Grid>
  );
}

Logo.defaultProps = defaultProps;

export default Logo;
