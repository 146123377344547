import { TabItems } from '../../types/components';
import { Translator } from '../../types/tools';

const menuItems: (t: Translator) => Array<TabItems> = (t) => ([{
  id: '1',
  title: t('reports'),
  testId: 'header-report-tab',
}]);

export default menuItems;
