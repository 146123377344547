import {
  makeStyles,
} from '@material-ui/core';

export const userMenuStyles = makeStyles((theme) => ({
  menuList: {
    width: '100%',
    '& .MuiListItem-root': {
      padding: '16px calc(20px + 8%)',
    },
  },
  userMenuContainer: {
    width: '100%',
  },
  downArrow: {
    position: 'relative',
    display: 'block',
    height: '8px',
    paddingLeft: '5px',
    '&::before': {
      position: 'absolute',
      display: 'block',
      content: '""',
      border: '8px solid transparent', /* adjust size */
      top: 0,
      borderTopColor: theme.palette.primary.main, /* Color */
    },
    '&::after': {
      position: 'absolute',
      display: 'block',
      content: '""',
      border: '8px solid transparent', /* adjust size */
      top: '-3px', /* adjust thickness */
      borderTopColor: 'white', /* Match background colour */
    },
  },
}));

export default userMenuStyles;
