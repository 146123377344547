import React, { lazy } from 'react';
import { AccessProps } from '../views/Access';
import routesDictionary from './routesDict';

const AsyncDashboard: React.LazyExoticComponent<React.FC> = lazy(() => import('../views/Dashboard'));
const AsyncReport: React.LazyExoticComponent<React.FC> = lazy(() => import('../views/Report'));
const AsyncLogin: React.LazyExoticComponent<(props: AccessProps) => JSX.Element> = lazy(() => import('../views/Access'));
const AsyncComparison: React.LazyExoticComponent<React.FC> = lazy(() => import('../views/Comparison'));

interface RouteProps {
  path: string,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  component: React.LazyExoticComponent<any>,
  type: string,
  title: string,
}

const routes: Array<RouteProps> = [
  {
    path: routesDictionary.comparison,
    component: AsyncComparison,
    type: 'private',
    title: 'comparison',
  },
  {
    path: routesDictionary.report,
    component: AsyncReport,
    type: 'private',
    title: 'report',
  },
  {
    path: routesDictionary.dashboard,
    component: AsyncDashboard,
    type: 'private',
    title: 'dashboard',
  },
  {
    path: routesDictionary.login,
    component: AsyncLogin,
    type: 'public',
    title: 'dashboard',
  },
];

export default routes;
