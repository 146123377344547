interface ComposeRoute {
  (id: string | number): string
}

const routesDictionary = {
  comparison: '/comparison',
  dashboard: '/dashboard',
  report: '/report/:id',
  login: '/',
};

export const composeRoutes: Record<string, ComposeRoute> = {
  report: (id) => `/report/${id}`,
};

export default routesDictionary;
