import {
  Grid,
  makeStyles,
  styled,
} from '@material-ui/core';
import { GLOBAL_PADDING } from '../../styles/variables';

export const BodyContainer = styled(Grid)(({ theme }) => ({
  paddingLeft: GLOBAL_PADDING,
  paddingRight: GLOBAL_PADDING,
  paddingBottom: '72px',
  paddingTop: '70px',
  flexGrow: 1,
  [theme.breakpoints.down('md')]: {
    paddingLeft: theme.spacing(5),
    paddingRight: theme.spacing(5),
  },
}));

export const RootContainer = styled(Grid)(() => ({
  minHeight: '100vh',
  flexDirection: 'column',
}));

export const layoutStyles = makeStyles(() => ({
  coverDiv: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
}));
