import {
  makeStyles,
  AppBar,
  styled,
} from '@material-ui/core';

import { GLOBAL_PADDING } from '../../styles/variables';

export const CustomAppBar = styled(AppBar)(({ theme }) => ({
  position: 'fixed',
  paddingLeft: GLOBAL_PADDING,
  paddingRight: GLOBAL_PADDING,
  background: theme.palette.common.white,
  color: theme.palette.text.primary,
  height: '70px',
  alignItems: 'center',
  borderBottom: `1px solid ${theme.palette.divider}`,
  [theme.breakpoints.down('md')]: {
    paddingLeft: theme.spacing(5),
    paddingRight: theme.spacing(5),
  },
}));

export const useStyles = makeStyles((theme) => ({
  headerContainer: {
    height: '100%',
  },
  userMenuContainer: {
    position: 'relative',
    borderLeft: `1px solid ${theme.palette.divider}`,
    borderRight: `1px solid ${theme.palette.divider}`,
    padding: '0px 20px',
  },
}));
