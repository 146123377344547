import { makeStyles } from '@material-ui/core';

export const logoStyles = makeStyles(() => ({
  default: {
    height: '56px',
    width: '217px',
    cursor: 'pointer',
  },
  header: {
    height: '40px',
    width: '140px',
    cursor: 'pointer',
  },
}));

export default logoStyles;
