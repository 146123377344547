import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions =  {}
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /**
   * The `Date` scalar type represents a Date
   * value as specified by
   * [iso8601](https://en.wikipedia.org/wiki/ISO_8601).
   */
  Date: string;
  /**
   * The `Time` scalar type represents a Time value as
   * specified by
   * [iso8601](https://en.wikipedia.org/wiki/ISO_8601).
   */
  Time: any;
  /**
   * The `DateTime` scalar type represents a DateTime
   * value as specified by
   * [iso8601](https://en.wikipedia.org/wiki/ISO_8601).
   */
  DateTime: any;
  /** The `Decimal` scalar type represents a python Decimal. */
  Decimal: any;
  /**
   * The `GenericScalar` scalar type represents a generic
   * GraphQL scalar value that could be:
   * String, Boolean, Int, Float, List or Object.
   */
  GenericScalar: any;
};

export type Query = {
  __typename: 'Query';
  /** Get fund */
  getFund?: Maybe<FundType>;
  /** Get fund cards for dashboard */
  getFundCards?: Maybe<Array<Maybe<FundType>>>;
  /** Get information of a list of funds */
  getFunds?: Maybe<Array<Maybe<FundType>>>;
  /** Get user data from logged user */
  me?: Maybe<UserType>;
  /** Get list of options for user title */
  getTitleOptions?: Maybe<Array<Maybe<OptionType>>>;
};


export type QueryGetFundArgs = {
  id: Scalars['ID'];
};


export type QueryGetFundCardsArgs = {
  vagScore?: Maybe<ScoreFilterInput>;
  endowmentScore?: Maybe<ScoreFilterInput>;
  churchScore?: Maybe<ScoreFilterInput>;
  countryScore?: Maybe<ScoreFilterInput>;
};


export type QueryGetFundsArgs = {
  ids: Array<Maybe<Scalars['ID']>>;
};

/** Fund type information */
export type FundType = {
  __typename: 'FundType';
  id: Scalars['ID'];
  name: Scalars['String'];
  isin?: Maybe<Scalars['String']>;
  wkn?: Maybe<Scalars['String']>;
  benchmarks: Array<BenchmarkType>;
  vagScore: Scalars['Int'];
  vagScoreDescription: Scalars['String'];
  endowmentScore: Scalars['Int'];
  endowmentScoreDescription: Scalars['String'];
  churchScore: Scalars['Int'];
  churchScoreDescription: Scalars['String'];
  countryScore: Scalars['Int'];
  countryScoreDescription: Scalars['String'];
  /** If "is publish" is true, this fund will appear in the dashboard */
  isPublish: Scalars['Boolean'];
  ecwidProductId?: Maybe<Scalars['String']>;
  updatePreviousTimeseries: Scalars['Boolean'];
  assetinfo?: Maybe<AssetInfoType>;
  allocationbyregionSet: Array<AllocationByRegionType>;
  /** pdf link */
  pdfLink?: Maybe<Scalars['String']>;
  /** If user is allowed to download pdf */
  allowToDownload?: Maybe<Scalars['Boolean']>;
  timeseries: Array<TimeserieType>;
  classes: ByClassesType;
  sectors: Array<AllocationDataBaseType>;
  creditQualities: Array<AllocationDataBaseType>;
  countries: Array<AllocationDataBaseType>;
  dividendHistory: DividendHistoryType;
  creditQualityRecord: ByClassesType;
  lastYearAverageCreditQuality: LastYearAverageCreditQualityType;
  maxCreditQuality: Scalars['String'];
  minCreditQuality: Scalars['String'];
};

/** Benchmark */
export type BenchmarkType = {
  __typename: 'BenchmarkType';
  id: Scalars['ID'];
  name: Scalars['String'];
  isin?: Maybe<Scalars['String']>;
  wkn?: Maybe<Scalars['String']>;
  updatePreviousTimeseries: Scalars['Boolean'];
  /** This is required to upload timeseries to CIOS */
  currencyCode?: Maybe<Scalars['String']>;
  fundSet: Array<FundType>;
  timeseries: Array<TimeserieType>;
};

/** Timeserie */
export type TimeserieType = {
  __typename: 'TimeserieType';
  id: Scalars['ID'];
  objectId: Scalars['Int'];
  date: Scalars['Date'];
  time: Scalars['Time'];
  closePrice: Scalars['Float'];
  provideBy?: Maybe<TimeserieProvideBy>;
  sentCios?: Maybe<Scalars['Boolean']>;
};



/** An enumeration. */
export enum TimeserieProvideBy {
  /** Tiib */
  A_1 = 'A_1',
  /** Cios */
  A_2 = 'A_2'
}

/** Asset info */
export type AssetInfoType = {
  __typename: 'AssetInfoType';
  fund: FundType;
  lastModified: Scalars['DateTime'];
  srri?: Maybe<Scalars['Int']>;
  investmentCompany?: Maybe<Scalars['String']>;
  currencyCode?: Maybe<Scalars['String']>;
  fundSizeValue?: Maybe<Scalars['Decimal']>;
  fundSizeDate?: Maybe<Scalars['Date']>;
  incomeDistribution?: Maybe<Scalars['Boolean']>;
  inceptionDate?: Maybe<Scalars['Date']>;
  topHoldings: Array<HoldingType>;
  sustainabilityRating?: Maybe<Scalars['String']>;
  portfolioEnvironmentalScore?: Maybe<Scalars['Decimal']>;
  environmentalRiskScoreVsGlobalCategory?: Maybe<Scalars['String']>;
  portfolioSocialScore?: Maybe<Scalars['Decimal']>;
  socialRiskScoreVsGlobalCategory?: Maybe<Scalars['String']>;
  portfolioGovernanceScore?: Maybe<Scalars['Decimal']>;
  governanceRiskScoreVsGlobalCategory?: Maybe<Scalars['String']>;
  historicalSustainabilityScore?: Maybe<Scalars['Decimal']>;
  legalStatus?: Maybe<Scalars['String']>;
  reportedCurrency?: Maybe<Scalars['String']>;
  shareClassSize?: Maybe<Scalars['Decimal']>;
  initialInvestmentValue?: Maybe<Scalars['Decimal']>;
  couponPaymentFreq?: Maybe<Scalars['String']>;
  investorTypeProfessional?: Maybe<Scalars['String']>;
  investorTypeRetail?: Maybe<Scalars['String']>;
  totalExpenseRatio?: Maybe<Scalars['Decimal']>;
};



/** Holding */
export type HoldingType = {
  __typename: 'HoldingType';
  id: Scalars['ID'];
  name: Scalars['String'];
  isin?: Maybe<Scalars['String']>;
  wkn?: Maybe<Scalars['String']>;
  currencyCode?: Maybe<Scalars['String']>;
  weight: Scalars['Decimal'];
  srri?: Maybe<Scalars['Int']>;
  sustainabilityRating?: Maybe<Scalars['String']>;
  /** return during the current year */
  returnYtd?: Maybe<Scalars['Decimal']>;
  /** return over the last 5 years */
  return5y?: Maybe<Scalars['Decimal']>;
  assetClass?: Maybe<Scalars['String']>;
  assetinfoSet: Array<AssetInfoType>;
};

/** Allocation by region */
export type AllocationByRegionType = {
  __typename: 'AllocationByRegionType';
  id: Scalars['ID'];
  name: Scalars['String'];
  code?: Maybe<Scalars['String']>;
  weight: Scalars['Decimal'];
  marketValue: Scalars['Decimal'];
};

/** By classes type */
export type ByClassesType = {
  __typename: 'ByClassesType';
  dates: Array<Scalars['Date']>;
  classes: Array<AllocationDataBaseType>;
};

/** Allocation data base */
export type AllocationDataBaseType = {
  __typename: 'AllocationDataBaseType';
  /** name of data */
  name?: Maybe<Scalars['String']>;
  data?: Maybe<Array<Scalars['Float']>>;
  /** default value for charts */
  type?: Maybe<Scalars['String']>;
};

export type DividendHistoryType = {
  __typename: 'DividendHistoryType';
  dates: Array<Scalars['Date']>;
  values: Array<DividendHistoryValues>;
};

export type DividendHistoryValues = {
  __typename: 'DividendHistoryValues';
  data: Array<Scalars['Float']>;
};

export type LastYearAverageCreditQualityType = {
  __typename: 'LastYearAverageCreditQualityType';
  dates: Array<Scalars['Date']>;
  values: Array<Scalars['String']>;
};

/** Score filter input */
export type ScoreFilterInput = {
  /** Greater equal than */
  gte: Scalars['Int'];
  /** Less equal than */
  lte: Scalars['Int'];
};

/** User information */
export type UserType = {
  __typename: 'UserType';
  id: Scalars['ID'];
  email: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  companyName?: Maybe<Scalars['String']>;
  registrationTimestamp: Scalars['DateTime'];
  profilePicture?: Maybe<Scalars['String']>;
  /** 1: Client, 2: Tiib employee */
  userType?: Maybe<Scalars['Int']>;
  title?: Maybe<Scalars['Int']>;
};

/** Option type for choices in frontend */
export type OptionType = {
  __typename: 'OptionType';
  /** Value number */
  value?: Maybe<Scalars['Int']>;
  /** Label string */
  label?: Maybe<Scalars['String']>;
};

export type Mutation = {
  __typename: 'Mutation';
  /** Sign up mutation */
  signUp?: Maybe<SignUpPayload>;
  auth?: Maybe<ObtainJsonWebToken>;
  verifyToken?: Maybe<Verify>;
  refreshToken?: Maybe<Refresh>;
  revokeToken?: Maybe<Revoke>;
};


export type MutationSignUpArgs = {
  input: SignUpInput;
};


export type MutationAuthArgs = {
  email: Scalars['String'];
  password: Scalars['String'];
};


export type MutationVerifyTokenArgs = {
  token?: Maybe<Scalars['String']>;
};


export type MutationRefreshTokenArgs = {
  refreshToken?: Maybe<Scalars['String']>;
};


export type MutationRevokeTokenArgs = {
  refreshToken?: Maybe<Scalars['String']>;
};

export type SignUpPayload = {
  __typename: 'SignUpPayload';
  email?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  companyName?: Maybe<Scalars['String']>;
  /** 1: Client, 2: Tiib employee */
  userType?: Maybe<Scalars['Int']>;
  /** 1: Mr., 2: Mrs. */
  title?: Maybe<Scalars['Int']>;
  /** May contain more than one error for same field. */
  errors?: Maybe<Array<Maybe<ErrorType>>>;
  /** May contain more than one error for same field. */
  fieldErrors?: Maybe<Array<Maybe<ErrorType>>>;
  /** If request is success */
  success?: Maybe<Scalars['Boolean']>;
  feedback?: Maybe<FeedbackType>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type ErrorType = {
  __typename: 'ErrorType';
  field: Scalars['String'];
  messages: Array<Scalars['String']>;
};

/** Feedback general type */
export type FeedbackType = {
  __typename: 'FeedbackType';
  /** Feedback message */
  message?: Maybe<Scalars['String']>;
  /** Type of feedback */
  type?: Maybe<FeedbackEnum>;
};

/** Feedback enum options */
export enum FeedbackEnum {
  SUCCESS = 'SUCCESS',
  WARNING = 'WARNING',
  ERROR = 'ERROR'
}

export type SignUpInput = {
  email: Scalars['String'];
  password: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  companyName: Scalars['String'];
  /** 1: Client, 2: Tiib employee */
  userType: Scalars['Int'];
  /** 1: Mr., 2: Mrs. */
  title?: Maybe<Scalars['Int']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type ObtainJsonWebToken = {
  __typename: 'ObtainJSONWebToken';
  payload: Scalars['GenericScalar'];
  refreshExpiresIn: Scalars['Int'];
  user?: Maybe<UserType>;
  token: Scalars['String'];
  refreshToken: Scalars['String'];
};


export type Verify = {
  __typename: 'Verify';
  payload: Scalars['GenericScalar'];
};

export type Refresh = {
  __typename: 'Refresh';
  payload: Scalars['GenericScalar'];
  refreshExpiresIn: Scalars['Int'];
  token: Scalars['String'];
  refreshToken: Scalars['String'];
};

export type Revoke = {
  __typename: 'Revoke';
  revoked: Scalars['Int'];
};

export type LoginMutationVariables = Exact<{
  email: Scalars['String'];
  password: Scalars['String'];
}>;


export type LoginMutation = { __typename: 'Mutation', auth?: Maybe<{ __typename: 'ObtainJSONWebToken', token: string, refreshExpiresIn: number, refreshToken: string, payload: any, user?: Maybe<{ __typename: 'UserType', id: string, userType?: Maybe<number> }> }> };

export type SignUpMutationVariables = Exact<{
  input: SignUpInput;
}>;


export type SignUpMutation = { __typename: 'Mutation', signUp?: Maybe<{ __typename: 'SignUpPayload', success?: Maybe<boolean>, fieldErrors?: Maybe<Array<Maybe<{ __typename: 'ErrorType', field: string, messages: Array<string> }>>>, feedback?: Maybe<{ __typename: 'FeedbackType', message?: Maybe<string>, type?: Maybe<FeedbackEnum> }> }> };

export type CompareAnnualPerformanceQueryVariables = Exact<{
  ids: Array<Maybe<Scalars['ID']>> | Maybe<Scalars['ID']>;
}>;


export type CompareAnnualPerformanceQuery = { __typename: 'Query', getFunds?: Maybe<Array<Maybe<{ __typename: 'FundType', id: string, name: string, dividendHistory: { __typename: 'DividendHistoryType', dates: Array<string>, values: Array<{ __typename: 'DividendHistoryValues', data: Array<number> }> } }>>> };

export type AllocationDataFragment = { __typename: 'AllocationDataBaseType', name?: Maybe<string>, data?: Maybe<Array<number>>, type?: Maybe<string> };

export type CompareInvestmentStructureQueryVariables = Exact<{
  ids: Array<Maybe<Scalars['ID']>> | Maybe<Scalars['ID']>;
}>;


export type CompareInvestmentStructureQuery = { __typename: 'Query', getFunds?: Maybe<Array<Maybe<{ __typename: 'FundType', id: string, name: string, minCreditQuality: string, maxCreditQuality: string, classes: { __typename: 'ByClassesType', dates: Array<string>, classes: Array<(
        { __typename: 'AllocationDataBaseType' }
        & AllocationDataFragment
      )> }, sectors: Array<(
      { __typename: 'AllocationDataBaseType' }
      & AllocationDataFragment
    )>, creditQualities: Array<(
      { __typename: 'AllocationDataBaseType' }
      & AllocationDataFragment
    )>, countries: Array<(
      { __typename: 'AllocationDataBaseType' }
      & AllocationDataFragment
    )>, lastYearAverageCreditQuality: { __typename: 'LastYearAverageCreditQualityType', dates: Array<string>, values: Array<string> } }>>> };

export type ComparePerformanceQueryVariables = Exact<{
  ids: Array<Maybe<Scalars['ID']>> | Maybe<Scalars['ID']>;
}>;


export type ComparePerformanceQuery = { __typename: 'Query', getFunds?: Maybe<Array<Maybe<{ __typename: 'FundType', id: string, name: string, timeseries: Array<{ __typename: 'TimeserieType', date: string, value: number }>, benchmarks: Array<{ __typename: 'BenchmarkType', id: string, name: string, timeseries: Array<{ __typename: 'TimeserieType', date: string, value: number }> }> }>>> };

export type ComparePositionDataQueryVariables = Exact<{
  ids: Array<Maybe<Scalars['ID']>> | Maybe<Scalars['ID']>;
}>;


export type ComparePositionDataQuery = { __typename: 'Query', getFunds?: Maybe<Array<Maybe<{ __typename: 'FundType', id: string, name: string, isin?: Maybe<string>, wkn?: Maybe<string>, vagScore: number, endowmentScore: number, churchScore: number, countryScore: number, assetinfo?: Maybe<{ __typename: 'AssetInfoType', srri?: Maybe<number>, investmentCompany?: Maybe<string>, currencyCode?: Maybe<string>, fundSizeValue?: Maybe<any>, fundSizeDate?: Maybe<string>, incomeDistribution?: Maybe<boolean>, inceptionDate?: Maybe<string>, legalStatus?: Maybe<string>, reportedCurrency?: Maybe<string>, shareClassSize?: Maybe<any>, initialInvestmentValue?: Maybe<any>, couponPaymentFreq?: Maybe<string>, investorTypeProfessional?: Maybe<string>, investorTypeRetail?: Maybe<string>, totalExpenseRatio?: Maybe<any>, topHoldings: Array<{ __typename: 'HoldingType', name: string, isin?: Maybe<string>, wkn?: Maybe<string>, weight: any, currencyCode?: Maybe<string>, srri?: Maybe<number>, sustainabilityRating?: Maybe<string>, returnYtd?: Maybe<any>, return5y?: Maybe<any>, assetClass?: Maybe<string> }> }> }>>> };

export type CompareSustainabilityQueryVariables = Exact<{
  ids: Array<Maybe<Scalars['ID']>> | Maybe<Scalars['ID']>;
}>;


export type CompareSustainabilityQuery = { __typename: 'Query', getFunds?: Maybe<Array<Maybe<{ __typename: 'FundType', id: string, name: string, assetinfo?: Maybe<{ __typename: 'AssetInfoType', sustainabilityRating?: Maybe<string>, portfolioEnvironmentalScore?: Maybe<any>, environmentalRiskScoreVsGlobalCategory?: Maybe<string>, portfolioSocialScore?: Maybe<any>, socialRiskScoreVsGlobalCategory?: Maybe<string>, portfolioGovernanceScore?: Maybe<any>, governanceRiskScoreVsGlobalCategory?: Maybe<string>, historicalSustainabilityScore?: Maybe<any> }> }>>> };

export type AnnualPerformanceQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type AnnualPerformanceQuery = { __typename: 'Query', getFund?: Maybe<{ __typename: 'FundType', id: string, name: string, dividendHistory: { __typename: 'DividendHistoryType', dates: Array<string>, values: Array<{ __typename: 'DividendHistoryValues', data: Array<number> }> } }> };

export type FundQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type FundQuery = { __typename: 'Query', getFund?: Maybe<{ __typename: 'FundType', id: string, name: string, pdfLink?: Maybe<string>, ecwidProductId?: Maybe<string>, allowToDownload?: Maybe<boolean> }> };

export type FundCardsQueryVariables = Exact<{
  vagScore?: Maybe<ScoreFilterInput>;
  endowmentScore?: Maybe<ScoreFilterInput>;
  churchScore?: Maybe<ScoreFilterInput>;
  countryScore?: Maybe<ScoreFilterInput>;
}>;


export type FundCardsQuery = { __typename: 'Query', getFundCards?: Maybe<Array<Maybe<{ __typename: 'FundType', id: string, name: string, vagScore: number, endowmentScore: number, churchScore: number, countryScore: number }>>> };

export type FundScoreQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type FundScoreQuery = { __typename: 'Query', getFund?: Maybe<{ __typename: 'FundType', id: string, vagScore: number, vagScoreDescription: string, endowmentScore: number, endowmentScoreDescription: string, churchScore: number, churchScoreDescription: string, countryScore: number, countryScoreDescription: string }> };

export type InvestmentStructureQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type InvestmentStructureQuery = { __typename: 'Query', getFund?: Maybe<{ __typename: 'FundType', id: string, name: string, minCreditQuality: string, maxCreditQuality: string, classes: { __typename: 'ByClassesType', dates: Array<string>, classes: Array<(
        { __typename: 'AllocationDataBaseType' }
        & AllocationDataFragment
      )> }, sectors: Array<(
      { __typename: 'AllocationDataBaseType' }
      & AllocationDataFragment
    )>, creditQualities: Array<(
      { __typename: 'AllocationDataBaseType' }
      & AllocationDataFragment
    )>, countries: Array<(
      { __typename: 'AllocationDataBaseType' }
      & AllocationDataFragment
    )>, allocationbyregionSet: Array<{ __typename: 'AllocationByRegionType', id: string, name: string, code?: Maybe<string>, weight: any, market_value: any }>, creditQualityRecord: { __typename: 'ByClassesType', dates: Array<string>, classes: Array<(
        { __typename: 'AllocationDataBaseType' }
        & AllocationDataFragment
      )> }, lastYearAverageCreditQuality: { __typename: 'LastYearAverageCreditQualityType', dates: Array<string>, values: Array<string> } }> };

export type PerformanceQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type PerformanceQuery = { __typename: 'Query', getFund?: Maybe<{ __typename: 'FundType', id: string, name: string, timeseries: Array<{ __typename: 'TimeserieType', date: string, value: number }>, benchmarks: Array<{ __typename: 'BenchmarkType', id: string, name: string, timeseries: Array<{ __typename: 'TimeserieType', date: string, value: number }> }> }> };

export type PositionDataQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type PositionDataQuery = { __typename: 'Query', getFund?: Maybe<{ __typename: 'FundType', id: string, name: string, isin?: Maybe<string>, wkn?: Maybe<string>, assetinfo?: Maybe<{ __typename: 'AssetInfoType', srri?: Maybe<number>, investmentCompany?: Maybe<string>, currencyCode?: Maybe<string>, fundSizeValue?: Maybe<any>, fundSizeDate?: Maybe<string>, incomeDistribution?: Maybe<boolean>, inceptionDate?: Maybe<string>, legalStatus?: Maybe<string>, reportedCurrency?: Maybe<string>, shareClassSize?: Maybe<any>, initialInvestmentValue?: Maybe<any>, couponPaymentFreq?: Maybe<string>, investorTypeProfessional?: Maybe<string>, investorTypeRetail?: Maybe<string>, totalExpenseRatio?: Maybe<any>, topHoldings: Array<{ __typename: 'HoldingType', name: string, isin?: Maybe<string>, wkn?: Maybe<string>, weight: any, currencyCode?: Maybe<string>, srri?: Maybe<number>, sustainabilityRating?: Maybe<string>, returnYtd?: Maybe<any>, return5y?: Maybe<any>, assetClass?: Maybe<string> }> }> }> };

export type SustainabilityQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type SustainabilityQuery = { __typename: 'Query', getFund?: Maybe<{ __typename: 'FundType', id: string, name: string, assetinfo?: Maybe<{ __typename: 'AssetInfoType', sustainabilityRating?: Maybe<string>, portfolioEnvironmentalScore?: Maybe<any>, environmentalRiskScoreVsGlobalCategory?: Maybe<string>, portfolioSocialScore?: Maybe<any>, socialRiskScoreVsGlobalCategory?: Maybe<string>, portfolioGovernanceScore?: Maybe<any>, governanceRiskScoreVsGlobalCategory?: Maybe<string>, historicalSustainabilityScore?: Maybe<any> }> }> };

export type MeQueryVariables = Exact<{ [key: string]: never; }>;


export type MeQuery = { __typename: 'Query', me?: Maybe<{ __typename: 'UserType', id: string, email: string, firstName: string, lastName: string, companyName?: Maybe<string>, registrationTimestamp: any, profilePicture?: Maybe<string>, userType?: Maybe<number> }> };

export type TitleOptionsQueryVariables = Exact<{ [key: string]: never; }>;


export type TitleOptionsQuery = { __typename: 'Query', getTitleOptions?: Maybe<Array<Maybe<{ __typename: 'OptionType', value?: Maybe<number>, label?: Maybe<string> }>>> };

export const AllocationDataFragmentDoc = gql`
    fragment allocationData on AllocationDataBaseType {
  name
  data
  type
}
    `;
export const LoginDocument = gql`
    mutation Login($email: String!, $password: String!) {
  auth(email: $email, password: $password) {
    token
    refreshExpiresIn
    refreshToken
    payload
    user {
      id
      userType
    }
  }
}
    `;
export type LoginMutationFn = Apollo.MutationFunction<LoginMutation, LoginMutationVariables>;

/**
 * __useLoginMutation__
 *
 * To run a mutation, you first call `useLoginMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginMutation, { data, loading, error }] = useLoginMutation({
 *   variables: {
 *      email: // value for 'email'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useLoginMutation(baseOptions?: Apollo.MutationHookOptions<LoginMutation, LoginMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<LoginMutation, LoginMutationVariables>(LoginDocument, options);
      }
export type LoginMutationHookResult = ReturnType<typeof useLoginMutation>;
export type LoginMutationResult = Apollo.MutationResult<LoginMutation>;
export type LoginMutationOptions = Apollo.BaseMutationOptions<LoginMutation, LoginMutationVariables>;
export const SignUpDocument = gql`
    mutation SignUp($input: SignUpInput!) {
  signUp(input: $input) {
    success
    fieldErrors {
      field
      messages
    }
    feedback {
      message
      type
    }
  }
}
    `;
export type SignUpMutationFn = Apollo.MutationFunction<SignUpMutation, SignUpMutationVariables>;

/**
 * __useSignUpMutation__
 *
 * To run a mutation, you first call `useSignUpMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSignUpMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [signUpMutation, { data, loading, error }] = useSignUpMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSignUpMutation(baseOptions?: Apollo.MutationHookOptions<SignUpMutation, SignUpMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SignUpMutation, SignUpMutationVariables>(SignUpDocument, options);
      }
export type SignUpMutationHookResult = ReturnType<typeof useSignUpMutation>;
export type SignUpMutationResult = Apollo.MutationResult<SignUpMutation>;
export type SignUpMutationOptions = Apollo.BaseMutationOptions<SignUpMutation, SignUpMutationVariables>;
export const CompareAnnualPerformanceDocument = gql`
    query CompareAnnualPerformance($ids: [ID]!) {
  getFunds(ids: $ids) {
    id
    name
    dividendHistory {
      dates
      values {
        data
      }
    }
  }
}
    `;

/**
 * __useCompareAnnualPerformanceQuery__
 *
 * To run a query within a React component, call `useCompareAnnualPerformanceQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompareAnnualPerformanceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompareAnnualPerformanceQuery({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useCompareAnnualPerformanceQuery(baseOptions: Apollo.QueryHookOptions<CompareAnnualPerformanceQuery, CompareAnnualPerformanceQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CompareAnnualPerformanceQuery, CompareAnnualPerformanceQueryVariables>(CompareAnnualPerformanceDocument, options);
      }
export function useCompareAnnualPerformanceLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CompareAnnualPerformanceQuery, CompareAnnualPerformanceQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CompareAnnualPerformanceQuery, CompareAnnualPerformanceQueryVariables>(CompareAnnualPerformanceDocument, options);
        }
export type CompareAnnualPerformanceQueryHookResult = ReturnType<typeof useCompareAnnualPerformanceQuery>;
export type CompareAnnualPerformanceLazyQueryHookResult = ReturnType<typeof useCompareAnnualPerformanceLazyQuery>;
export type CompareAnnualPerformanceQueryResult = Apollo.QueryResult<CompareAnnualPerformanceQuery, CompareAnnualPerformanceQueryVariables>;
export const CompareInvestmentStructureDocument = gql`
    query CompareInvestmentStructure($ids: [ID]!) {
  getFunds(ids: $ids) {
    id
    name
    classes {
      dates
      classes {
        ...allocationData
      }
    }
    sectors {
      ...allocationData
    }
    creditQualities {
      ...allocationData
    }
    countries {
      ...allocationData
    }
    minCreditQuality
    maxCreditQuality
    lastYearAverageCreditQuality {
      dates
      values
    }
  }
}
    ${AllocationDataFragmentDoc}`;

/**
 * __useCompareInvestmentStructureQuery__
 *
 * To run a query within a React component, call `useCompareInvestmentStructureQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompareInvestmentStructureQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompareInvestmentStructureQuery({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useCompareInvestmentStructureQuery(baseOptions: Apollo.QueryHookOptions<CompareInvestmentStructureQuery, CompareInvestmentStructureQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CompareInvestmentStructureQuery, CompareInvestmentStructureQueryVariables>(CompareInvestmentStructureDocument, options);
      }
export function useCompareInvestmentStructureLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CompareInvestmentStructureQuery, CompareInvestmentStructureQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CompareInvestmentStructureQuery, CompareInvestmentStructureQueryVariables>(CompareInvestmentStructureDocument, options);
        }
export type CompareInvestmentStructureQueryHookResult = ReturnType<typeof useCompareInvestmentStructureQuery>;
export type CompareInvestmentStructureLazyQueryHookResult = ReturnType<typeof useCompareInvestmentStructureLazyQuery>;
export type CompareInvestmentStructureQueryResult = Apollo.QueryResult<CompareInvestmentStructureQuery, CompareInvestmentStructureQueryVariables>;
export const ComparePerformanceDocument = gql`
    query ComparePerformance($ids: [ID]!) {
  getFunds(ids: $ids) {
    id
    name
    timeseries {
      date
      value: closePrice
    }
    benchmarks {
      id
      name
      timeseries {
        date
        value: closePrice
      }
    }
  }
}
    `;

/**
 * __useComparePerformanceQuery__
 *
 * To run a query within a React component, call `useComparePerformanceQuery` and pass it any options that fit your needs.
 * When your component renders, `useComparePerformanceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useComparePerformanceQuery({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useComparePerformanceQuery(baseOptions: Apollo.QueryHookOptions<ComparePerformanceQuery, ComparePerformanceQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ComparePerformanceQuery, ComparePerformanceQueryVariables>(ComparePerformanceDocument, options);
      }
export function useComparePerformanceLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ComparePerformanceQuery, ComparePerformanceQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ComparePerformanceQuery, ComparePerformanceQueryVariables>(ComparePerformanceDocument, options);
        }
export type ComparePerformanceQueryHookResult = ReturnType<typeof useComparePerformanceQuery>;
export type ComparePerformanceLazyQueryHookResult = ReturnType<typeof useComparePerformanceLazyQuery>;
export type ComparePerformanceQueryResult = Apollo.QueryResult<ComparePerformanceQuery, ComparePerformanceQueryVariables>;
export const ComparePositionDataDocument = gql`
    query ComparePositionData($ids: [ID]!) {
  getFunds(ids: $ids) {
    id
    name
    isin
    wkn
    vagScore
    endowmentScore
    churchScore
    countryScore
    assetinfo {
      srri
      investmentCompany
      currencyCode
      fundSizeValue
      fundSizeDate
      incomeDistribution
      inceptionDate
      legalStatus
      reportedCurrency
      shareClassSize
      initialInvestmentValue
      couponPaymentFreq
      investorTypeProfessional
      investorTypeRetail
      totalExpenseRatio
      topHoldings {
        name
        isin
        wkn
        weight
        currencyCode
        srri
        sustainabilityRating
        returnYtd
        return5y
        assetClass
      }
    }
  }
}
    `;

/**
 * __useComparePositionDataQuery__
 *
 * To run a query within a React component, call `useComparePositionDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useComparePositionDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useComparePositionDataQuery({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useComparePositionDataQuery(baseOptions: Apollo.QueryHookOptions<ComparePositionDataQuery, ComparePositionDataQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ComparePositionDataQuery, ComparePositionDataQueryVariables>(ComparePositionDataDocument, options);
      }
export function useComparePositionDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ComparePositionDataQuery, ComparePositionDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ComparePositionDataQuery, ComparePositionDataQueryVariables>(ComparePositionDataDocument, options);
        }
export type ComparePositionDataQueryHookResult = ReturnType<typeof useComparePositionDataQuery>;
export type ComparePositionDataLazyQueryHookResult = ReturnType<typeof useComparePositionDataLazyQuery>;
export type ComparePositionDataQueryResult = Apollo.QueryResult<ComparePositionDataQuery, ComparePositionDataQueryVariables>;
export const CompareSustainabilityDocument = gql`
    query CompareSustainability($ids: [ID]!) {
  getFunds(ids: $ids) {
    id
    name
    assetinfo {
      sustainabilityRating
      portfolioEnvironmentalScore
      environmentalRiskScoreVsGlobalCategory
      portfolioSocialScore
      socialRiskScoreVsGlobalCategory
      portfolioGovernanceScore
      governanceRiskScoreVsGlobalCategory
      historicalSustainabilityScore
    }
  }
}
    `;

/**
 * __useCompareSustainabilityQuery__
 *
 * To run a query within a React component, call `useCompareSustainabilityQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompareSustainabilityQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompareSustainabilityQuery({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useCompareSustainabilityQuery(baseOptions: Apollo.QueryHookOptions<CompareSustainabilityQuery, CompareSustainabilityQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CompareSustainabilityQuery, CompareSustainabilityQueryVariables>(CompareSustainabilityDocument, options);
      }
export function useCompareSustainabilityLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CompareSustainabilityQuery, CompareSustainabilityQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CompareSustainabilityQuery, CompareSustainabilityQueryVariables>(CompareSustainabilityDocument, options);
        }
export type CompareSustainabilityQueryHookResult = ReturnType<typeof useCompareSustainabilityQuery>;
export type CompareSustainabilityLazyQueryHookResult = ReturnType<typeof useCompareSustainabilityLazyQuery>;
export type CompareSustainabilityQueryResult = Apollo.QueryResult<CompareSustainabilityQuery, CompareSustainabilityQueryVariables>;
export const AnnualPerformanceDocument = gql`
    query AnnualPerformance($id: ID!) {
  getFund(id: $id) {
    id
    name
    dividendHistory {
      dates
      values {
        data
      }
    }
  }
}
    `;

/**
 * __useAnnualPerformanceQuery__
 *
 * To run a query within a React component, call `useAnnualPerformanceQuery` and pass it any options that fit your needs.
 * When your component renders, `useAnnualPerformanceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAnnualPerformanceQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useAnnualPerformanceQuery(baseOptions: Apollo.QueryHookOptions<AnnualPerformanceQuery, AnnualPerformanceQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AnnualPerformanceQuery, AnnualPerformanceQueryVariables>(AnnualPerformanceDocument, options);
      }
export function useAnnualPerformanceLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AnnualPerformanceQuery, AnnualPerformanceQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AnnualPerformanceQuery, AnnualPerformanceQueryVariables>(AnnualPerformanceDocument, options);
        }
export type AnnualPerformanceQueryHookResult = ReturnType<typeof useAnnualPerformanceQuery>;
export type AnnualPerformanceLazyQueryHookResult = ReturnType<typeof useAnnualPerformanceLazyQuery>;
export type AnnualPerformanceQueryResult = Apollo.QueryResult<AnnualPerformanceQuery, AnnualPerformanceQueryVariables>;
export const FundDocument = gql`
    query Fund($id: ID!) {
  getFund(id: $id) {
    id
    name
    pdfLink
    ecwidProductId
    allowToDownload
  }
}
    `;

/**
 * __useFundQuery__
 *
 * To run a query within a React component, call `useFundQuery` and pass it any options that fit your needs.
 * When your component renders, `useFundQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFundQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useFundQuery(baseOptions: Apollo.QueryHookOptions<FundQuery, FundQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FundQuery, FundQueryVariables>(FundDocument, options);
      }
export function useFundLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FundQuery, FundQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FundQuery, FundQueryVariables>(FundDocument, options);
        }
export type FundQueryHookResult = ReturnType<typeof useFundQuery>;
export type FundLazyQueryHookResult = ReturnType<typeof useFundLazyQuery>;
export type FundQueryResult = Apollo.QueryResult<FundQuery, FundQueryVariables>;
export const FundCardsDocument = gql`
    query FundCards($vagScore: ScoreFilterInput, $endowmentScore: ScoreFilterInput, $churchScore: ScoreFilterInput, $countryScore: ScoreFilterInput) {
  getFundCards(
    vagScore: $vagScore
    endowmentScore: $endowmentScore
    churchScore: $churchScore
    countryScore: $countryScore
  ) {
    id
    name
    vagScore
    endowmentScore
    churchScore
    countryScore
  }
}
    `;

/**
 * __useFundCardsQuery__
 *
 * To run a query within a React component, call `useFundCardsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFundCardsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFundCardsQuery({
 *   variables: {
 *      vagScore: // value for 'vagScore'
 *      endowmentScore: // value for 'endowmentScore'
 *      churchScore: // value for 'churchScore'
 *      countryScore: // value for 'countryScore'
 *   },
 * });
 */
export function useFundCardsQuery(baseOptions?: Apollo.QueryHookOptions<FundCardsQuery, FundCardsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FundCardsQuery, FundCardsQueryVariables>(FundCardsDocument, options);
      }
export function useFundCardsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FundCardsQuery, FundCardsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FundCardsQuery, FundCardsQueryVariables>(FundCardsDocument, options);
        }
export type FundCardsQueryHookResult = ReturnType<typeof useFundCardsQuery>;
export type FundCardsLazyQueryHookResult = ReturnType<typeof useFundCardsLazyQuery>;
export type FundCardsQueryResult = Apollo.QueryResult<FundCardsQuery, FundCardsQueryVariables>;
export const FundScoreDocument = gql`
    query FundScore($id: ID!) {
  getFund(id: $id) {
    id
    vagScore
    vagScoreDescription
    endowmentScore
    endowmentScoreDescription
    churchScore
    churchScoreDescription
    countryScore
    countryScoreDescription
  }
}
    `;

/**
 * __useFundScoreQuery__
 *
 * To run a query within a React component, call `useFundScoreQuery` and pass it any options that fit your needs.
 * When your component renders, `useFundScoreQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFundScoreQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useFundScoreQuery(baseOptions: Apollo.QueryHookOptions<FundScoreQuery, FundScoreQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FundScoreQuery, FundScoreQueryVariables>(FundScoreDocument, options);
      }
export function useFundScoreLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FundScoreQuery, FundScoreQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FundScoreQuery, FundScoreQueryVariables>(FundScoreDocument, options);
        }
export type FundScoreQueryHookResult = ReturnType<typeof useFundScoreQuery>;
export type FundScoreLazyQueryHookResult = ReturnType<typeof useFundScoreLazyQuery>;
export type FundScoreQueryResult = Apollo.QueryResult<FundScoreQuery, FundScoreQueryVariables>;
export const InvestmentStructureDocument = gql`
    query InvestmentStructure($id: ID!) {
  getFund(id: $id) {
    id
    name
    classes {
      dates
      classes {
        ...allocationData
      }
    }
    sectors {
      ...allocationData
    }
    creditQualities {
      ...allocationData
    }
    countries {
      ...allocationData
    }
    allocationbyregionSet {
      id
      name
      code
      weight
      market_value: marketValue
    }
    creditQualityRecord {
      dates
      classes {
        ...allocationData
      }
    }
    minCreditQuality
    maxCreditQuality
    lastYearAverageCreditQuality {
      dates
      values
    }
  }
}
    ${AllocationDataFragmentDoc}`;

/**
 * __useInvestmentStructureQuery__
 *
 * To run a query within a React component, call `useInvestmentStructureQuery` and pass it any options that fit your needs.
 * When your component renders, `useInvestmentStructureQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInvestmentStructureQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useInvestmentStructureQuery(baseOptions: Apollo.QueryHookOptions<InvestmentStructureQuery, InvestmentStructureQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<InvestmentStructureQuery, InvestmentStructureQueryVariables>(InvestmentStructureDocument, options);
      }
export function useInvestmentStructureLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<InvestmentStructureQuery, InvestmentStructureQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<InvestmentStructureQuery, InvestmentStructureQueryVariables>(InvestmentStructureDocument, options);
        }
export type InvestmentStructureQueryHookResult = ReturnType<typeof useInvestmentStructureQuery>;
export type InvestmentStructureLazyQueryHookResult = ReturnType<typeof useInvestmentStructureLazyQuery>;
export type InvestmentStructureQueryResult = Apollo.QueryResult<InvestmentStructureQuery, InvestmentStructureQueryVariables>;
export const PerformanceDocument = gql`
    query Performance($id: ID!) {
  getFund(id: $id) {
    id
    name
    timeseries {
      date
      value: closePrice
    }
    benchmarks {
      id
      name
      timeseries {
        date
        value: closePrice
      }
    }
  }
}
    `;

/**
 * __usePerformanceQuery__
 *
 * To run a query within a React component, call `usePerformanceQuery` and pass it any options that fit your needs.
 * When your component renders, `usePerformanceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePerformanceQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function usePerformanceQuery(baseOptions: Apollo.QueryHookOptions<PerformanceQuery, PerformanceQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PerformanceQuery, PerformanceQueryVariables>(PerformanceDocument, options);
      }
export function usePerformanceLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PerformanceQuery, PerformanceQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PerformanceQuery, PerformanceQueryVariables>(PerformanceDocument, options);
        }
export type PerformanceQueryHookResult = ReturnType<typeof usePerformanceQuery>;
export type PerformanceLazyQueryHookResult = ReturnType<typeof usePerformanceLazyQuery>;
export type PerformanceQueryResult = Apollo.QueryResult<PerformanceQuery, PerformanceQueryVariables>;
export const PositionDataDocument = gql`
    query PositionData($id: ID!) {
  getFund(id: $id) {
    id
    name
    isin
    wkn
    assetinfo {
      srri
      investmentCompany
      currencyCode
      fundSizeValue
      fundSizeDate
      incomeDistribution
      inceptionDate
      legalStatus
      reportedCurrency
      shareClassSize
      initialInvestmentValue
      couponPaymentFreq
      investorTypeProfessional
      investorTypeRetail
      totalExpenseRatio
      topHoldings {
        name
        isin
        wkn
        weight
        currencyCode
        srri
        sustainabilityRating
        returnYtd
        return5y
        assetClass
      }
    }
  }
}
    `;

/**
 * __usePositionDataQuery__
 *
 * To run a query within a React component, call `usePositionDataQuery` and pass it any options that fit your needs.
 * When your component renders, `usePositionDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePositionDataQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function usePositionDataQuery(baseOptions: Apollo.QueryHookOptions<PositionDataQuery, PositionDataQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PositionDataQuery, PositionDataQueryVariables>(PositionDataDocument, options);
      }
export function usePositionDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PositionDataQuery, PositionDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PositionDataQuery, PositionDataQueryVariables>(PositionDataDocument, options);
        }
export type PositionDataQueryHookResult = ReturnType<typeof usePositionDataQuery>;
export type PositionDataLazyQueryHookResult = ReturnType<typeof usePositionDataLazyQuery>;
export type PositionDataQueryResult = Apollo.QueryResult<PositionDataQuery, PositionDataQueryVariables>;
export const SustainabilityDocument = gql`
    query Sustainability($id: ID!) {
  getFund(id: $id) {
    id
    name
    assetinfo {
      sustainabilityRating
      portfolioEnvironmentalScore
      environmentalRiskScoreVsGlobalCategory
      portfolioSocialScore
      socialRiskScoreVsGlobalCategory
      portfolioGovernanceScore
      governanceRiskScoreVsGlobalCategory
      historicalSustainabilityScore
    }
  }
}
    `;

/**
 * __useSustainabilityQuery__
 *
 * To run a query within a React component, call `useSustainabilityQuery` and pass it any options that fit your needs.
 * When your component renders, `useSustainabilityQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSustainabilityQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useSustainabilityQuery(baseOptions: Apollo.QueryHookOptions<SustainabilityQuery, SustainabilityQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SustainabilityQuery, SustainabilityQueryVariables>(SustainabilityDocument, options);
      }
export function useSustainabilityLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SustainabilityQuery, SustainabilityQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SustainabilityQuery, SustainabilityQueryVariables>(SustainabilityDocument, options);
        }
export type SustainabilityQueryHookResult = ReturnType<typeof useSustainabilityQuery>;
export type SustainabilityLazyQueryHookResult = ReturnType<typeof useSustainabilityLazyQuery>;
export type SustainabilityQueryResult = Apollo.QueryResult<SustainabilityQuery, SustainabilityQueryVariables>;
export const MeDocument = gql`
    query Me {
  me {
    id
    email
    firstName
    lastName
    companyName
    registrationTimestamp
    profilePicture
    userType
  }
}
    `;

/**
 * __useMeQuery__
 *
 * To run a query within a React component, call `useMeQuery` and pass it any options that fit your needs.
 * When your component renders, `useMeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMeQuery({
 *   variables: {
 *   },
 * });
 */
export function useMeQuery(baseOptions?: Apollo.QueryHookOptions<MeQuery, MeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MeQuery, MeQueryVariables>(MeDocument, options);
      }
export function useMeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MeQuery, MeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MeQuery, MeQueryVariables>(MeDocument, options);
        }
export type MeQueryHookResult = ReturnType<typeof useMeQuery>;
export type MeLazyQueryHookResult = ReturnType<typeof useMeLazyQuery>;
export type MeQueryResult = Apollo.QueryResult<MeQuery, MeQueryVariables>;
export const TitleOptionsDocument = gql`
    query TitleOptions {
  getTitleOptions {
    value
    label
  }
}
    `;

/**
 * __useTitleOptionsQuery__
 *
 * To run a query within a React component, call `useTitleOptionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useTitleOptionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTitleOptionsQuery({
 *   variables: {
 *   },
 * });
 */
export function useTitleOptionsQuery(baseOptions?: Apollo.QueryHookOptions<TitleOptionsQuery, TitleOptionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TitleOptionsQuery, TitleOptionsQueryVariables>(TitleOptionsDocument, options);
      }
export function useTitleOptionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TitleOptionsQuery, TitleOptionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TitleOptionsQuery, TitleOptionsQueryVariables>(TitleOptionsDocument, options);
        }
export type TitleOptionsQueryHookResult = ReturnType<typeof useTitleOptionsQuery>;
export type TitleOptionsLazyQueryHookResult = ReturnType<typeof useTitleOptionsLazyQuery>;
export type TitleOptionsQueryResult = Apollo.QueryResult<TitleOptionsQuery, TitleOptionsQueryVariables>;