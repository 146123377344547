const resourceDe = {
  translation: {
    global: {
      investors: 'VAG-Anleger',
      foundations: 'Stiftungen',
      churches: 'Kirchen',
      government: 'Kommunen',
      thousand: 'Tausend',
      million: 'Millionen',
      billion: 'Milliarden',
      trillion: 'Billionen',
      noCredit: 'Dieser Fonds investiert nur in Aktien, weswegen für ihn die Kreditqualität nicht gerated wird.',
    },
    errors: {
      required: 'Bitte füllen Sie dieses Feld aus.',
      email: 'Die Eingabe entspricht nicht dem Format für E-Mail-Adressen.',
      termsRequiredError: 'Bitte akzeptieren Sie die Datenschutzerklärung und die AGB um fortzufahren.',
      letters: 'Bitte nur Buchstaben verwenden',
      number: 'Bitte geben Sie die Nummer ein',
      lettersNumbers: 'Bitte verwenden Sie nur Buchstaben und Zahlen',
    },
    viewTitles: {
      dashboard: 'Dashboard',
      report: 'Funds',
      comparison: 'Comparison',
    },
    header: {
      reports: 'Übersicht',
      goBack: '< Zurück zur Übersicht',
    },
    footer: {
      description: '© 2021 TIIB The Institutional Investors Board GmbH',
      emailLabel: 'Grafenberger Allee 297 | 40237 Düsseldorf | E-Mail:',
      email: 'info@tiib.de',
      frontendVersionCustomer: 'Customer Frontend: ',
      backendVersion: '- Backend: ',
      apiVersion: '- API: ',
      imprint: 'Impressum',
      usageInstructions: 'Nutzungshinweise',
      conditions: 'AGB',
      riskWarnings: 'Risikohinweise',
      dataProtection: 'Datenschutzerklärung',
      cookiePolicy: 'Cookie-Richtlinie',
      blog: 'Blog',
      imprintLink: 'https://www.tiib.de/impressum',
      usageInstructionsLink: 'https://www.tiib.de/nutzungshinweise',
      conditionsLink: 'https://www.tiib.de/agb',
      riskWarningsLink: 'https://www.tiib.de/risikohinweise',
      dataProtectionLink: 'https://www.tiib.de/datenschutz',
      cookiePolicyLink: 'https://www.tiib.de/cookies',
      blogLink: 'https://www.tiib.de/blog',
    },
    reports: {
      positionData: 'Fondsdaten & Positionen',
      performance: 'Wertentwicklung',
      investStructure: 'Anlagestruktur',
      sustainability: 'Nachhaltigkeit',
      download: 'Clarity Report erstellen',
      purchase: 'Report kaufen',
    },
    dashboard: {
      title: 'Auswahl Investmentfonds',
    },
    fundCard: {
      compare: 'Fonds vergleichen',
      cancel: 'Nicht vergleichen',
    },
    access: {
      login: 'Anmelden',
      register: 'Neu Registrieren',
    },
    login: {
      submitButton: 'Anmelden',
      forgotPassword: 'Passwort vergessen?',
      loginError: 'Bitte verwenden Sie ein gültiges Passwort und/oder Nutzernamen',
    },
    register: {
      submitButton: 'Registrieren',
      haveAccount: 'Haben Sie bereits ein Konto? Hier anmelden',
    },
    form: {
      email: 'E-mail Adresse',
      emailPlaceholder: 'Geben Sie ihre Email Adresse ein',
      password: 'Passwort',
      passwordPlaceholder: 'Passwort eingeben',
      showPassword: 'Anzeigen',
      salutation: 'Anrede',
      salutationPlaceholder: 'Wählen Sie eine Anrede',
      firstName: 'Vorname',
      firstNamePlaceholder: 'Geben Sie Ihren Vornamen ein',
      surname: 'Nachname',
      surnamePlaceholder: 'Geben Sie Ihren Nachnamen ein',
      companyName: 'Firmenname',
      companyNamePlaceholder: 'Geben Sie Ihren Firmennamen ein',
      repeatPassword: 'Passwort wiederholen',
      termsAndConditions: 'Ja, ich stimme den AGBs und den Datenschutz- bestimmungen von TiiB zu',
      caption: '*Pflichtfelder',
    },
    userMenu: {
      logout: 'Abmelden',
    },
    fundList: {},
    scoreCard: {
      limiter: 'von 5',
    },
    riskProfile: {
      title: 'Risikoprofil (SRRI)',
      caption: '1Der Synthetische Risiko- und Ertragsindikator (SRRI), ist eine Kennzahl aus der Finanzwirtschaft, welche als Indikator für die Höhe der Schwankungen eines Fonds steht. Anhand der Intensität der Wertschwankungen, die auch als Volatilität bezeichnet wird, kann geschlussfolgert werden, wie hoch das Risiko von Kursverlusten ist bzw. wie hoch die Chancen sind, Kursgewinne zu realisieren. Die Kennzahl wird nach europäischen und deutschen Vorschriften einheitlich berechnet und von den Fondgesellschaften veröffentlicht.',
    },
    fundData: {
      title: 'Fondsdaten',
      isin: 'ISIN / WKN',
      management: 'Kapitalverwaltungsgesellschaft & Fondsmanagement',
      legalStatus: 'Rechtlicher Status',
      currency: 'Fondswährung',
      volume: 'Fondsvolumen',
      shareVolume: 'Volumen der Anteilsklasse',
      minInvest: 'Mindestanlage',
      useIncome: 'Ertragsverwendung',
      distributionFrequency: 'Ausschüttungsturnus',
      minPayout: 'Mindestausschüttung/ -ziel',
      fundCirculation: 'Fondsauflage',
      retail: 'Anteilsklasse Institutionell / Retail',
      managementFee: 'Ausgabeaufschlag / Verwaltungsvergütung',
      depositaryFee: 'Verwahrstellenvergütung',
      ter: 'TER (Gesamtkostenquote)',
      shareClasses: '(alle Anteilsklassen)',
      accumulating: 'thesaurierend',
      pouringOut: 'ausschüttend',
    },
    topSecurity: {
      title: 'Die 10 größten Wertpapierpositionen',
      instrumentHeader: 'Instrumentenname',
      isinHeader: 'ISIN/WKN',
      proportionHeader: 'Anteil',
      sharedHeader: 'Anzahl Anteile',
      assetHeader: 'Assetklasse',
      srriHeader: 'SRRI',
      rankHeader: 'ESG Rang Morningstar',
      annualHeader: 'Rendite lfd. Jahr',
      yieldHeader: 'Rendite 5J',
      caption: '1Rang in % von 1.000 Fonds im Vergleich zur globalen Kategorie. Das Morningstar Nachhaltigkeitsrating ist ein Maß dafür, wie gut die Unternehmen im Portfolio ihr ESG-Risiko im Vergleich zu den anderen Fonds in der globalen Fondskategorie.',
    },
    performanceChart: {
      title: 'Wertentwicklung 5 Jahre',
      benchmark: 'Benchmark',
    },
    annualDistribution: {
      title: 'Jahresausschüttungen je Fondsanteil 5 Jahre',
    },
    scoreFilter: {
      scoreSelector: 'Score: {{ currentValue }} bis {{ maxValue }}',
    },
    investmentChart: {
      title: 'Anlagestruktur 5 Jahre',
    },
    investmentProperties: {
      sectors: 'Sektoren',
      creditQuality: 'Kreditqualität',
      countries: 'Länder',
    },
    regionChart: {
      title: 'Regionen',
    },
    creditQuality: {
      title: 'Historische Kreditqualtität 5 Jahre',
      rating: 'Rating in 1 Jahr:',
    },
    sustainAnalysis: {
      title: 'Nachhaltigkeitsanalyse (ESG)',
      scoreLineTitle: 'Historischer Sustainability Score',
      minDescription: 'Beste Score, niedriges ESG-Risiko',
      maxDescription: 'schlechteste Score, hoheres ESG-Risiko',
      scoreDescription: 'Fondsscore',
      caption1: '1Das Morningstar Nachhaltigkeitsrating ist ein Maß dafür, wie gut die Unternehmen im Portfolio ihr ESG-Risiko im Vergleich zu den anderen Fonds in der globalen Fondskategorie.',
      caption2: '2Der Morningstar Historical Sustainability Score ist ein gewichteter Durchschnitt der letzten 12 Monate der Morningstar Portfolio Sustainability Scores. Für das historische Portfolio-Score werden die neueren Portfolios stärker gewichtet als die älteren Portfolios. Basierend auf dem Morningstar Historical Sustainability Score werden den Fonds absolute Kategorien und prozentuale Ränge innerhalb der Morningstar Global Categories zugewiesen.',
      caption3: '3Das Morningstar Sustainability Rating (Globe Rating) eines Fonds ist sein normalverteilter Ordinalscore und sein deskriptiver Rang im Verhältnis zur globalen Kategorie des Fonds. Höhere Ratings sind besser und deuten darauf hin, dass ein Fonds im Durchschnitt mehr seines Vermögens in Unternehmen investiert hat, die ein geringeres ESG-Risiko haben, wie es von Sustainalytics charakterisiert wird.',
    },
    analysisTable: {
      rating: 'Nachhaltigkeits-Rating Morningstar',
      rank: 'Rang in % im Vergleich zur globalen Kategorie',
      pillars: 'ESG-Pfeiler',
      environment: 'Umwelt',
      social: 'Soziales',
      governance: 'Governance',
      low: 'Niedrig',
      belowAverage: 'Unterdurchschnittlich',
      average: 'Durchschnittlich',
      aboveAverage: 'Überdurchschnittlich',
      high: 'Hoch',
      '-': '-',
    },
    fundSelector: {
      title: 'Sie können bis zu 3 Fonds vergleichen:',
      firstFound: 'Zuerst Fonds auswählen',
      secondFound: 'Zweite Fonds auswählen',
      thirdFound: 'Dritte Fonds auswählen',
      compare: 'Fonds vergleichen',
    },
    fundFilter: {
      title: 'Ecovis Score filtern',
    },
    scoreComparison: {
      title: 'Ecovis Scores im Vergleich',
    },
    riskComparison: {
      title: 'Risikoprofile im Vergleich',
    },
    fundComparison: {
      title: 'Fondsdaten im Vergleich',
      isin: 'ISIN / WKN',
      management: 'Fondsmanagement',
      legalStatus: 'Rechtlicher Status',
      currency: 'Fondswährung',
      volume: 'Fondsvolumen',
      shareVolume: 'Volumen der Anteilsklasse',
      minInvest: 'Mindestanlage',
      useIncome: 'Ertragsverwendung',
      distributionFrequency: 'Ausschüttungsturnus',
      fundCirculation: 'Fondsauflage',
      managementFee: 'Ausgabeaufschlag',
      depositaryFee: 'Verwahrstellenvergütung',
      ter: 'Gesamtkostenquote',
      accumulating: 'thesaurierend',
      pouringOut: 'ausschüttend',
      managementFeeSecond: 'Managementgebühr',
    },
    riskScore: {
      limiter: 'von 7',
      defensive: 'Defensiv',
      defensiveDescription: '(geringes Risiko)',
      offensive: 'Offensiv',
      offensiveDescription: '(hohes Risiko)',
      moderate: 'Moderat',
      moderateDescription: '(mittleres Risiko)',
    },
    performanceCumulation: {
      title: 'Wertentwicklung 5 Jahre im Vergleich',
    },
    annualDistributionCompare: {
      title: 'Jahresausschüttungen je Fondsanteil 5 Jahre im Vergleich',
    },
    sectionComparison: {
      title: 'Sektoren im Vergleich',
    },
    creditComparison: {
      title: 'Kreditqualität im Vergleich',
    },
    countryComparison: {
      title: 'Länder im Vergleich',
    },
    ratingComparison: {
      title: 'Durchschnittliches Kreditqualität-Rating 1 Jahr',
    },
    qualityTable: {
      min: 'Minumum: {{ value }}',
      max: 'Maximum: {{ value }}',
      average: 'Durchschnittliches Rating: {{ value }}',
    },
    analysisComparison: {
      title: 'Nachhaltigkeitsanalysen (ESG) im Vergleich',
      caption1: '1Das Morningstar Nachhaltigkeitsrating ist ein Maß dafür, wie gut die Unternehmen im Portfolio ihr ESG-Risiko im Vergleich zu den anderen Fonds in der globalen Fondskategorie.',
      caption2: '3Das Morningstar Sustainability Rating (Globe Rating) eines Fonds ist sein normalverteilter Ordinalscore und sein deskriptiver Rang im Verhältnis zur globalen Kategorie des Fonds. Höhere Ratings sind besser und deuten darauf hin, dass ein Fonds im Durchschnitt mehr seines Vermögens in Unternehmen investiert hat, die ein geringeres ESG-Risiko haben, wie es von Sustainalytics charakterisiert wird.',
    },
    compare: {
      positionData: 'Fondsdaten & Positionen',
      performance: 'Wertentwicklung',
      investStructure: 'Anlagestruktur',
      sustainability: 'Nachhaltigkeit',
      title: 'Fondsvergleich',
    },
    downloadPdf: {
      noAccess: 'Dieser ClarityReport steht Ihnen nur nach Kauf zur Verfügung. In der Sektion”Kaufen” können Sie den Report erwerben.',
      noLink: 'Wir möchten uns für Ihre Unannehmlichkeiten entschuldigen - Die PDF Datei dieses ClarityReports ist aktuell aus technischen Gründen nicht verfügbar. Bitte kontaktieren Sie TIIB.',
    },
  },
};

export default resourceDe;
