/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useReducer } from 'react';
import { getAuthToken } from '../../utils/tools';
import { MeQuery, useMeLazyQuery } from '../../api/graphql/api';
import { SetLoginState } from '../../types/tools';

interface AppContextProviderProps {
  children: React.ReactNode,
  setLoginState: SetLoginState,
  isLogin: boolean,
}

type AppState = Record<string, any>

const initialState: AppState = {
  me: {},
};

export const AppContext = React.createContext(initialState);

interface Action {
  type: 'default' | 'updateInfo' | 'refetchInfo' | 'clearInfo',
  data?: AppState
}

interface ActionFunction {
  default: () => AppState,
  updateInfo: (action: Action) => AppState,
  refetchInfo: (
    action: Action,
    state: AppState,
    refetch: any) => AppState,
  clearInfo: () => AppState
}

const actions: ActionFunction = {
  default: () => (initialState),
  updateInfo: (action) => ({
    me: action.data?.me,
  }),
  refetchInfo: (action, state, refetch) => {
    refetch();

    return state;
  },
  clearInfo: () => (initialState),
};

function AppContextProvider(props: AppContextProviderProps): JSX.Element {
  const { children, setLoginState, isLogin } = props;
  const [meRequest, {
    data, refetch, called, error,
  }] = useMeLazyQuery({ fetchPolicy: 'no-cache' });
  const reducer: (state: MeQuery, action: Action) => any = (
    state, action = { type: 'default' },
  ) => actions[action.type](action, state, called ? refetch : meRequest);
  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    if (isLogin) {
      meRequest();
    }
  }, [meRequest, isLogin]);

  useEffect(() => {
    if (data && data.me && getAuthToken()) {
      dispatch({ type: 'updateInfo', data: { me: data.me } });
    }
  }, [data, error]);

  return (
    <AppContext.Provider value={{ state, dispatch }}>
      {children}
    </AppContext.Provider>
  );
}

export default AppContextProvider;
