export const LANGUAGE_LIST = ['EN', 'DE']; /** global: LANGUAGE_LIST */
export const I18LANGUAGE = 'app-language';
export const DEFAULT_LANGUAGE = 'DE'; /** global: DEFAULT_LANGUAGE */
export const BACKEND_LANGUAGE: Record<string, string> = {
  EN: 'en-us',
  DE: 'de',
};
export const AUTH_TOKEN = 'NS7b`qP<_k4~9{Nsz&'; /** global: AUTH_TOKEN */
export const REFRESH_TOKEN = 'NS7b`qP<_Rk4~9{Nsz&R'; /** global: REFRESH_TOKEN */
export const API_URL = typeof process.env.REACT_APP_DEV_API_URL !== 'undefined'
  ? process.env.REACT_APP_DEV_API_URL : undefined; /** global: API_URL */
export const FRONTEND_VERSION_CUSTOMER = 0.01;
export const FRONTEND_VERSION_EMPLOYEE = 0.00;
export const BACKEND_VERSION = 0.01;
export const API_VERSION = 0.01;

interface ScoreVariable {
  name: string,
  key: string,
  scoreKey: 'vagScore' | 'endowmentScore' | 'churchScore' | 'countryScore'
  descriptionKey: 'vagScoreDescription' | 'endowmentScoreDescription' | 'churchScoreDescription' | 'countryScoreDescription'
}

export const SCORE_VARIABLES: Array<ScoreVariable> = [
  {
    name: 'investors',
    key: 'investors',
    scoreKey: 'vagScore',
    descriptionKey: 'vagScoreDescription',
  },
  {
    name: 'foundations',
    key: 'foundations',
    scoreKey: 'endowmentScore',
    descriptionKey: 'endowmentScoreDescription',
  },
  {
    name: 'churches',
    key: 'churches',
    scoreKey: 'churchScore',
    descriptionKey: 'churchScoreDescription',
  },
  {
    name: 'government',
    key: 'government',
    scoreKey: 'countryScore',
    descriptionKey: 'countryScoreDescription',
  },
];

export const CHART_COLOR_LIST = ['#C8E8C7', '#7ED2CF', '#1FBBD5', '#0098C6', '#1971AF', '#1F4998', '#1D2281', '#151759', '#FFBFA4', '#FD8B96', '#FA528A', '#D0358D', '#9C2097', '#6D0E94', '#530A70', '#39084D'];
