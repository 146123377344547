import React, { Suspense } from 'react';
import { Switch } from 'react-router-dom';
import Footer from '../../components/Footer';
import Header from '../../components/Header';
import AppContextProvider from '../../context/App';
import MakeRouteWithSubRoutes from '../../routes';
import routes from '../../routes/routeList';
import { SetLoginState } from '../../types/tools';
import {
  BodyContainer,
  layoutStyles,
  RootContainer,
} from './styles';

interface LayoutProps {
  isLogin: boolean,
  setLoginState: SetLoginState,
}

function Layout(props: LayoutProps): JSX.Element {
  const styles = layoutStyles();
  const { isLogin, setLoginState } = props;

  return (
    <AppContextProvider setLoginState={setLoginState} isLogin={isLogin}>
      <RootContainer container data-testid="root-layout">
        {isLogin && <Header setLoginState={setLoginState} />}
        <BodyContainer container>
          <div className={styles.coverDiv}>
            <Suspense fallback={<div />}>
              <Switch>
                {
                routes.map(
                  (route) => (
                    <MakeRouteWithSubRoutes
                      key={route.path}
                      type={route.type}
                      path={route.path}
                      title={route.title}
                      component={route.component}
                      setLoginState={setLoginState}
                      isLogin={isLogin}
                    />
                  ),
                )
              }
              </Switch>
            </Suspense>
          </div>
        </BodyContainer>
        {isLogin && <Footer />}
      </RootContainer>
    </AppContextProvider>
  );
}

export default Layout;
