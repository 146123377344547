import { TFunction } from 'i18next';
import { OptionsObject, SnackbarKey, SnackbarMessage } from 'notistack';
import { Translator, SetLoginState } from '../types/tools';
/* eslint-disable @typescript-eslint/no-explicit-any */
import { FieldRecordValue, FieldValue, Score } from '../types/components';
import {
  API_URL,
  AUTH_TOKEN,
  BACKEND_LANGUAGE,
  DEFAULT_LANGUAGE,
  I18LANGUAGE,
  REFRESH_TOKEN,
} from '../settings/constants';
import { FundType } from '../api/graphql/api';

/**
 * Function to get translation schema from i18next
 * @param {string} schemaName
 * @param {function} t
 */
export const getTranslatorSchema = (
  schemaName: string, t: TFunction,
) => (
  key: string, global?: boolean, variables?: Record<string, string | number | undefined>,
): string => {
  if (global) return t(`global.${key}`, variables);

  return t(`${schemaName}.${key}`, variables);
};

/**
 * Function to get auth token
 * @function getAuthToken
 * @returns {String}
 */
export const getAuthToken: () => string | null = () => {
  const localToken = localStorage.getItem(AUTH_TOKEN);

  return localToken;
};

/**
 * Function to get refresh token
 * @function getRefreshToken
 * @returns {String}
 */
export const getRefreshToken: () => string | null = () => {
  const localToken = localStorage.getItem(REFRESH_TOKEN);

  return localToken;
};

interface RevokeToken {
  (url: string, refreshToken: string, onFinish: () => void): void
}

/**
 * Function to revoke refreshTokens
 * @function revokeToken
 * @param url Backend Url
 * @param refreshToken refresh token
 * @param onFinish custom function to fo on finish revoke token
 */
export const revokeToken: RevokeToken = (url, refreshToken, onFinish = () => { /** pass */ }) => {
  const revokeXhr = new XMLHttpRequest();
  const revokeObj = {
    operationName: null,
    query: `mutation ($refreshToken: String!) {
      revokeToken(refreshToken: $refreshToken) {
        revoked
      }
    }`,
    variables: { refreshToken },
  };

  revokeXhr.open('POST', url);
  revokeXhr.setRequestHeader('Content-Type', 'application/json');
  revokeXhr.onload = () => {
    onFinish();
  };

  revokeXhr.onerror = () => { /** pass */ };
  revokeXhr.send(JSON.stringify(revokeObj));
};

/**
 * Function to set login tokens
 * @function setLoginTokens
 * @param accessToken: access token provided by backend
 * @param refreshToken: refresh token provided by backend
 */
export const setLoginTokens: (accessToken?: string, refreshToken?: string) => void = (
  accessToken, refreshToken,
) => {
  if (accessToken) {
    localStorage.setItem(AUTH_TOKEN, accessToken);
  }
  if (refreshToken) {
    localStorage.setItem(REFRESH_TOKEN, refreshToken);
  }
};

/**
 * Function to format form variables to graphql variables
 * @param values: Values Objects
 * @returns { key: value }
 */
export const getRawVariables: (
  values: Record<string, FieldValue>) => Record<string, FieldRecordValue> = (
    values,
  ) => {
    const keys = Object.keys(values);
    const response: Record<string, FieldRecordValue> = {};
    keys.forEach((key) => {
      const field = values[key];
      response[key] = field.value;
    });

    return response;
  };

interface SetFormErrors {
  (values: Record<string, FieldValue>,
    setValues: React.Dispatch<React.SetStateAction<Record<string, FieldValue>>>,
    data: any
    ): void
}

/**
 * Function to set form errors in AutoForm format
 * @param values Object form values
 * @param setValues function to set value state
 * @param data Backend income data
 */
export const setFormErrors: SetFormErrors = (values, setValues, data) => {
  const newValues = { ...values };
  if (data.fieldErrors) {
    data.fieldErrors.forEach((element: { messages: [any]; field: string | number; }) => {
      const [firstMessage] = element.messages;
      newValues[element.field].error = true;
      newValues[element.field].helperText = firstMessage;
    });
  }

  setValues(newValues);
};

interface Logout {
  (
    setLoginState: SetLoginState,
    logoutMessage?: string,
    enqueueSnackbar?: (message: SnackbarMessage, options?: OptionsObject | undefined) => SnackbarKey
  ): void
}

/**
 * Function to logout
 * @function logout
 * @param {*} setLoginState Change state function.
 * @param {*} logoutMessage Message to display in a notification.
 * @param {*} enqueueSnackbar Function to display notification.
 */
export const logout: Logout = (setLoginState, logoutMessage, enqueueSnackbar) => {
  const deleteLocal = () => {
    localStorage.clear();
    sessionStorage.clear();
    setLoginState(false);
    if (logoutMessage && enqueueSnackbar) {
      enqueueSnackbar(logoutMessage, { variant: 'error', key: 'logout', preventDuplicate: true });
    }
  };
  revokeToken(API_URL || '', getRefreshToken() || '', deleteLocal);
};

/**
 * Function to get app language
 * @function getLanguage
 * @returns {String}
 */
export const getLanguage: () => string = () => {
  const language = localStorage.getItem(I18LANGUAGE);

  return !language ? DEFAULT_LANGUAGE : language;
};

/**
 * Function to get backend language
 * @function getBackLanguage
 * @returns {String}
 */
export const getBackLanguage: () => void = () => (BACKEND_LANGUAGE[getLanguage()]);

interface NumToFormat {
  (
  number: number | null,
  decimals?: number,
  type?: 'default' | 'currency' | 'percent',
  currency?: string,
  ) : string
}

/**
 * Function to format a number to germany style.
 * @function numToFormat
 * @param number: Number
 * @param currency: If is necessary add symbol
 */
export const numToFormat: NumToFormat = (number, decimals = 0, type = 'default', currency = 'EUR') => {
  let value = number;
  if (typeof number === 'string') {
    value = parseFloat(number);
  }

  if (value === null) return 'N/V';
  if (typeof value === 'undefined') return '';

  if (type === 'percent') {
    value /= 100;
  }

  const types: Record<string, Intl.NumberFormatOptions> = {
    default: { minimumFractionDigits: decimals, maximumFractionDigits: decimals },
    currency: {
      style: 'currency',
      currency,
      minimumFractionDigits: decimals,
      maximumFractionDigits: decimals,
    },
    percent: {
      style: 'percent',
      minimumFractionDigits: decimals,
      maximumFractionDigits: decimals,
    },
  };

  return value.toLocaleString('de-DE', types[type]);
};

interface AbbreviationNumber {
  (num: number, incomeFixed: number, translator: Translator): string | null
}

const labels: (translator: Translator) => Array<string> = (translator) => [
  '',
  ` ${translator('thousand', true)}`,
  ` ${translator('million', true)}`,
  ` ${translator('billion', true)}`,
  ` ${translator('trillion', true)}`,
];

/**
 * Function to generate number abbreviation on string
 * @param num: current value
 * @param incomeFixed: decimals on abbreviation
 * @param translator: to handle multiple languages
 * @returns number abbreviation
 */
export const abbreviateNumber: AbbreviationNumber = (num = 0, incomeFixed = 0, translator) => {
  let fixed = incomeFixed;
  if (num === null) { return null; }
  if (num === 0) { return '0'; }

  fixed = (!fixed || fixed < 0) ? 0 : fixed;
  const power = num.toPrecision(2).split('e');
  const floor = power.length === 1
    ? 0 : Math.floor(
      Math.min(parseFloat(power[1].slice(1)), 14) / 3,
    );
  const dividePower = floor < 1
    ? num.toFixed(0 + fixed) : (num / (10 ** (floor * 3))).toFixed(1 + fixed);
  const temp = parseFloat(dividePower);
  const abs = temp < 0 ? parseFloat(dividePower) : Math.abs(temp);
  const result = numToFormat(abs, 2) + labels(translator)[floor];

  return result;
};

const stringScore: Record<string, number> = {
  default: 0,
  Low: 1,
  'Below Average': 2,
  Average: 3,
  'Above Average': 4,
  High: 5,
};

const scoreTranslation: Record<string, string> = {
  default: '-',
  Low: 'low',
  'Below Average': 'belowAverage',
  Average: 'average',
  'Above Average': 'aboveAverage',
  High: 'high',
};

/**
 * Function to map score string to number
 * @param value: score category
 * @returns Score Number
 */
export const mapStringScore:(value?: string) => number = (value = 'default') => stringScore[value];

/**
 * Function to get key translation of score category
 * @param value: score category
 * @returns Score key translation
 */
export const mapStringTranslation:(value?: string) => string = (value = 'default') => scoreTranslation[value];

export type InnerFund = Pick<FundType, 'id' | 'name' | 'vagScore' | 'endowmentScore' | 'churchScore' | 'countryScore'>

export const getScores: (fund: InnerFund, translator: Translator) => Array<Score> = (
  fund, translator,
) => {
  const scores = [];
  scores.push({ name: translator('investors', true), value: fund.vagScore });
  scores.push({ name: translator('foundations', true), value: fund.endowmentScore });
  scores.push({ name: translator('churches', true), value: fund.churchScore });
  scores.push({ name: translator('government', true), value: fund.countryScore });
  return scores;
};

interface EncodeQueryData {
  (data: Record<string, string | number>) : string
}

export const encodeQueryData: EncodeQueryData = (data) => {
  const ret: Array<string> = [];
  const keys = Object.keys(data);
  keys.forEach((key) => {
    ret.push(`${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`);
  });

  return ret.join('&');
};
