import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import resourceEn from './en';
import resourceDe from './de';
import {
  DEFAULT_LANGUAGE,
  I18LANGUAGE,
  LANGUAGE_LIST,
} from '../settings/constants';

const resources = {
  EN: resourceEn,
  DE: resourceDe,
};

const currentLanguage = localStorage.getItem(I18LANGUAGE) || DEFAULT_LANGUAGE;

i18n
  .use(initReactI18next)
  .init({
    resources,
    lng: currentLanguage,
    fallbackLng: LANGUAGE_LIST,
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
