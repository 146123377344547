import React from 'react';
import { Grid, Link, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import {
  API_VERSION,
  BACKEND_VERSION,
  FRONTEND_VERSION_CUSTOMER,
} from '../../settings/constants';
import { getTranslatorSchema } from '../../utils/tools';
import Logo from '../Logo';
import {
  CustomFooterGrid,
  footerStyles,
} from './styles';
import links from './constants';

function Footer(): JSX.Element {
  const styles = footerStyles();
  const { t } = useTranslation();
  const translator = getTranslatorSchema('footer', t);

  return (
    <CustomFooterGrid item xs={12}>
      <Grid item xs={12} className={styles.sectionMargin}>
        <Logo header justify="flex-start" />
      </Grid>
      <Grid container item className={styles.sectionMargin}>
        <Typography variant="caption" color="textPrimary" noWrap>
          {translator('description')}
        </Typography>
      </Grid>
      <Grid container item className={styles.sectionMargin}>
        <Typography variant="caption" color="textPrimary" noWrap>
          {translator('emailLabel')}
          {' '}
          <Link href={`mailto:${translator('email')}`}>{translator('email')}</Link>
        </Typography>
      </Grid>
      <Grid container item className={styles.sectionMargin}>
        <Typography variant="caption" color="textPrimary" noWrap>
          {translator('frontendVersionCustomer')}
          {FRONTEND_VERSION_CUSTOMER}
          {' '}
          {translator('backendVersion')}
          {BACKEND_VERSION}
          {' '}
          {translator('apiVersion')}
          {API_VERSION}
        </Typography>
      </Grid>
      <Grid container item className={styles.linksFooter}>
        <Typography variant="caption" color="textPrimary" noWrap>
          {links.map((link, index) => (
            <span key={link.textKey}>
              <Link href={translator(link.linkKey)} target="_blank">
                {translator(link.textKey)}
              </Link>
              {index !== links.length - 1 && ' | ' }
            </span>
          ))}
        </Typography>
      </Grid>
    </CustomFooterGrid>
  );
}

export default Footer;
