import { makeStyles } from '@material-ui/core';
import React, { useState } from 'react';
import {
  createMargin,
  createPadding,
} from '../../styles/utils';
import { SetLoginState } from '../../types/tools';
import {
  getAuthToken,
} from '../../utils/tools';
import Apollo from '../Apollo';

interface LoginState {
  isLogin: boolean
}

const utilsStyles = makeStyles((theme) => ({
  '@global': {
    ...createMargin(theme),
    ...createPadding(theme),
    '.h-100': {
      height: '100%',
    },
    '.w-100': {
      width: '100%',
    },
    '.text-left': {
      textAlign: 'left',
    },
    '.text-right': {
      textAlign: 'right',
    },
    '.text-center': {
      textAlign: 'center',
    },
    '.text-normal': {
      fontWeight: 'normal',
    },
    '.relative': {
      position: 'relative',
    },
    '.bold': {
      fontWeight: 'bold',
    },
    body: {
      fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    },
    sup: {
      fontSize: '8px',
    },
  },
}));

function Base(): JSX.Element {
  const [loginInternal, setLoginInternal] = useState<LoginState>({ isLogin: !!getAuthToken() });
  const setLoginState: SetLoginState = (newState) => {
    setLoginInternal({
      isLogin: newState,
    });
  };
  utilsStyles();

  return (<Apollo isLogin={loginInternal.isLogin} setLoginState={setLoginState} />);
}

export default Base;
