import React from 'react';
import './i18n';
import { ThemeProvider } from '@material-ui/core';
import './App.css';
import { BrowserRouter as Router } from 'react-router-dom';
import { SnackbarProvider } from 'notistack';
import theme from './styles/theme';
import Base from './wrappers/Base';

function App(): JSX.Element {
  return (
    <ThemeProvider theme={theme}>
      <SnackbarProvider maxSnack={3}>
        <Router>
          <Base />
        </Router>
      </SnackbarProvider>
    </ThemeProvider>
  );
}

export default App;
