import React, { ComponentType, FunctionComponent } from 'react';
import {
  Route,
  Redirect,
} from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import routesDictionary from './routesDict';
import {
  getTranslatorSchema,
} from '../utils/tools';
import { SetLoginState } from '../types/tools';

interface RouteProps {
  renderProps: Record<string, unknown>,
  isLogin: boolean,
  component: ComponentType,
}

export interface MakeRoutes {
  path: string,
  exact?: boolean,
  title: string,
  component: ComponentType,
  type?: string,
  isLogin?: boolean,
  setLoginState: SetLoginState,
}

const routeTypes: { [key: string]: FunctionComponent<RouteProps>; } = {
  public: (props: RouteProps) => {
    const {
      renderProps, component: Component,
      isLogin,
    } = props;
    if (isLogin) {
      return (
        <Redirect
          to={routesDictionary.dashboard}
        />
      );
    }

    // eslint-disable-next-line react/jsx-props-no-spreading
    return <Component {...renderProps} />;
  },
  private: (props: RouteProps) => {
    const {
      renderProps, component: Component,
      isLogin,
    } = props;

    if (!isLogin) {
      return (
        <Redirect
          to={routesDictionary.login}
        />
      );
    }

    // eslint-disable-next-line react/jsx-props-no-spreading
    return <Component {...renderProps} />;
  },
  persistent: (props: RouteProps) => {
    const {
      renderProps, component: Component,
    } = props;

    // eslint-disable-next-line react/jsx-props-no-spreading
    return <Component {...renderProps} />;
  },
};

function MakeRouteWithSubRoutes(props: MakeRoutes): JSX.Element {
  const { t } = useTranslation();
  const translator = getTranslatorSchema('viewTitles', t);
  const {
    path, exact, title,
    component: Component, type = 'private',
    isLogin = false, setLoginState,
  } = props;

  return (
    <Route
      path={path}
      exact={exact}
      render={(renderProps) => {
        const propsComponent = {
          ...renderProps,
          setLoginState,
        };
        window.scrollTo(0, 0);
        document.title = translator(title);

        return (
          routeTypes[type]({
            renderProps: propsComponent,
            component: Component,
            isLogin,
          })
        );
      }}
    />
  );
}

export default MakeRouteWithSubRoutes;
