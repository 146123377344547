import { createMuiTheme, fade, ThemeOptions } from '@material-ui/core/styles';
import transitions from '@material-ui/core/styles/transitions';

const primary = '#2254B9';
const textPrimary = '#4E4E4E';
const contrastTextPrimary = '#fff';
export const primaryDark = '#0047AC';
export const primaryLight = '#99B5DE';
const divider = '#E0E0E0';
export const grey = {
  400: '#B1B1B1',
  500: '#E3E3E3',
  600: '#8D8D8D',
  700: '#898989',
  800: '#A7A7A7',
};
const errorMain = '#E3440D';
const errorLight = '#FA8B2A';
export const requiredColor = '#3CBA92';
export const activeColor = '#336CBD';

export const innerTheme: ThemeOptions = {
  palette: {
    primary: {
      main: primary,
      contrastText: contrastTextPrimary,
    },
    text: {
      primary: textPrimary,
    },
    success: {
      main: '#5AD580',
    },
    warning: {
      main: '#FFD44A',
    },
    error: {
      main: errorMain,
      light: errorLight,
    },
    divider,
    grey,
  },
  typography: {
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    fontSize: 16,
    subtitle1: {
      fontSize: '16px',
      fontWeight: 500,
      lineHeight: 1.2,
    },
    subtitle2: {
      fontSize: '14px',
      fontWeight: 500,
      lineHeight: 1.3,
    },
    body1: {
      fontSize: '16px',
    },
    caption: {
      fontSize: '12px',
    },
    body2: {
      fontSize: '14px',
    },
    h1: {
      fontSize: '55px',
      fontWeight: 'bold',
    },
    h2: {
      fontSize: '32px',
      fontWeight: 'bold',
    },
    h3: {
      fontSize: '26px',
      fontWeight: 'bold',
    },
    h4: {
      fontSize: '20px',
    },
    button: {
      fontSize: '14px',
      textTransform: 'none',
    },
  },
  overrides: {
    MuiFormControl: {
      root: {
        width: '100%',
      },
    },
    MuiInputLabel: {
      root: {
        zIndex: 2,
      },
    },
    MuiCheckbox: {
      root: {
        color: grey[400],
        '& path': {
          stroke: 'white',
        },
      },
    },
    MuiFormControlLabel: {
      label: {
        fontSize: '14px',
        color: textPrimary,
        letterSpacing: 0,
      },
    },
    MuiFormLabel: {
      root: {
        color: textPrimary,
        '& + .MuiInput-formControl': {
          marginTop: '20px',
        },
        fontSize: '14px',
      },
      asterisk: {
        color: requiredColor,
      },
    },
    MuiTabs: {
      flexContainer: {
        borderBottom: `1px solid ${textPrimary}`,
      },
      indicator: {
        backgroundColor: primary,
      },
    },
    MuiInput: {
      underline: {
        '&.Mui-error:after': {
          transform: 'scaleX(0)',
        },
        '&.Mui-error input': {
          boxShadow: `${fade(errorMain, 0.25)} 0 0 0 0.2rem`,
          borderColor: errorMain,
        },
        '&.Mui-error .MuiSelect-root': {
          boxShadow: `${fade(errorMain, 0.25)} 0 0 0 0.2rem`,
          borderColor: errorMain,
        },
        '&:hover:not(.Mui-disabled):before': {
          borderBottom: '0px',
        },
        '&:before': {
          borderBottom: '0px',
        },
        '&.Mui-focused:after': {
          transform: 'scaleX(0)',
        },
      },
    },
    MuiButton: {
      root: {
        padding: '8px 16px',
        borderRadius: '2px',
      },
      containedPrimary: {
        fill: contrastTextPrimary,
      },
      contained: {
        width: '100%',
        fontWeight: 'bold',
        '&.Mui-disabled': {
          backgroundColor: `${grey[800]} !important`,
          color: 'white !important',
        },
      },
      textPrimary: {
        fill: primary,
      },
    },
    MuiInputBase: {
      input: {
        borderRadius: 4,
        position: 'relative',
        backgroundColor: 'white',
        border: '1px solid #ADB6BD',
        fontSize: '14px',
        width: '100%',
        padding: '15px',
        transition: transitions.create(['border-color', 'box-shadow']),
        '&:focus': {
          boxShadow: `${fade(primary, 0.25)} 0 0 0 0.2rem`,
          borderColor: primary,
        },

      },
    },
    MuiTableCell: {
      root: {
        padding: '0.5rem 20px',
      },
      head: {
        color: primaryDark,
        lineHeight: 1,
        fontWeight: 'bold',
        fontSize: '12px',
        backgroundColor: '#F2F2F2',
      },
    },
    MuiSlider: {
      root: {
        padding: '10px 0 !important',
        height: '4px',

        '&.Mui-disabled': {
          '& .MuiSlider-track': {
            color: divider,
          },
          '& .MuiSlider-mark': {
            backgroundColor: grey[800],
          },
        },
      },
      mark: {
        backgroundColor: grey[800],
        width: '4px',
        height: '4px',
        borderRadius: '2px',
      },
      markActive: {
        backgroundColor: activeColor,
      },
      track: {
        height: '4px',
        opacity: 1,
        color: primaryLight,
      },
      rail: {
        height: '4px',
        opacity: 1,
        color: divider,
      },
      thumb: {
        top: '11px',
        '&.Mui-disabled': {
          width: '12px',
          height: '12px',
          marginTop: '-5px',
          marginLeft: '-6px',
        },
      },
    },
  },
};

export const theme = createMuiTheme(innerTheme);

export default theme;
