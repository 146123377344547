import {
  Tab,
} from '@material-ui/core';
import React, { useState } from 'react';
import { TabItems } from '../../types/components';
import {
  CustomTabs,
  tabStyles,
} from './styles';

interface Props {
  items: Array<TabItems>,
  subTabs?: boolean,
  onChange?: (value: number) => void,
  'data-testid'?: string,
}

const defaultProps = {
  subTabs: false,
  onChange: () => { /** pass */ },
  'data-testid': undefined,
};

function TabMenu(props: Props): JSX.Element {
  const {
    items, subTabs, onChange = () => { /** pass */ }, 'data-testid': dataTestId,
  } = props;
  const styles = tabStyles();
  const [value, setValue] = useState<number>(0);
  const handleChange = (event: React.ChangeEvent<Record<string, unknown>>, newValue: number) => {
    setValue(newValue);
    onChange(newValue);
  };

  return (
    <CustomTabs
      data-testid={dataTestId}
      onChange={handleChange}
      value={value}
      className={subTabs ? styles.subTab : undefined}
    >
      {items.map((element) => (
        <Tab
          key={element.id}
          label={element.title}
          id={element.id}
          data-testid={element.testId}
        />
      ))}
    </CustomTabs>
  );
}

TabMenu.defaultProps = defaultProps;

export default TabMenu;
