const links = [
  {
    textKey: 'imprint',
    linkKey: 'imprintLink',
  },
  {
    textKey: 'usageInstructions',
    linkKey: 'usageInstructionsLink',
  },
  {
    textKey: 'conditions',
    linkKey: 'conditionsLink',
  },
  {
    textKey: 'riskWarnings',
    linkKey: 'riskWarningsLink',
  },
  {
    textKey: 'dataProtection',
    linkKey: 'dataProtectionLink',
  },
  {
    textKey: 'cookiePolicy',
    linkKey: 'cookiePolicyLink',
  },
  {
    textKey: 'blog',
    linkKey: 'blogLink',
  },
];

export default links;
