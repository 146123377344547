import React from 'react';
import { useSnackbar } from 'notistack';
import {
  ApolloProvider,
  concat,
  fromPromise,
} from '@apollo/react-hooks';
import {
  ApolloClient,
  InMemoryCache,
} from '@apollo/client';
import { createUploadLink } from 'apollo-upload-client';
import { onError } from 'apollo-link-error';
import {
  getAuthToken,
  getRefreshToken,
  revokeToken,
  setLoginTokens,
  getBackLanguage,
  logout,
} from '../../utils/tools';
import { API_URL } from '../../settings/constants';
import LayoutView from '../Layout';

const expiredToken = 'Signature has expired';
const refreshTokenExpired = 'Session expired';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const onExpiredToken = (url) => new Promise((resolve, reject) => {
  const xhr = new XMLHttpRequest();

  const obj = {
    operationName: null,
    query: `mutation ($refreshToken: String!) {
      refreshToken(refreshToken: $refreshToken) {
          payload
          refreshExpiresIn
          token
          refreshToken
      }
    }`,
    variables: { refreshToken: getRefreshToken() },
  };
  xhr.open('POST', url || '');
  xhr.setRequestHeader('Content-Type', 'application/json');
  xhr.onload = (e) => {
    const { responseText } = e.target;
    const parseResponse = JSON.parse(responseText);

    if (parseResponse.data) {
      const { refreshToken } = parseResponse.data;
      if (refreshToken) {
        setLoginTokens(refreshToken.token);
        revokeToken(url, refreshToken.refreshToken);

        return resolve({
          token: refreshToken.token,
          refreshToken: refreshToken.refreshToken,
        });
      }
    }
    return reject(Error(refreshTokenExpired));
  };

  xhr.onerror = reject;

  xhr.send(JSON.stringify(obj));
});

export const customFetch = (
  url, opts = {},
) => {
  const token = getAuthToken();
  // eslint-disable-next-line no-param-reassign
  opts.headers.authorization = !token
    ? '' : `JWT ${token}`;
  opts.headers['Accept-Language'] = getBackLanguage();

  return new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest();
    const isFormData = opts.body instanceof FormData;
    let obj = opts.body;
    if (!isFormData) {
      obj = JSON.parse(opts.body);
      Object.keys(obj.variables).forEach(
        (key) => obj.variables[key] === undefined && delete obj.variables[key],
      );
    }

    xhr.open(opts.method || 'get', url);
    const optsHeaders = Object.keys(opts.headers || {});
    for (let index = 0; index < optsHeaders.length; index += 1) {
      const k = optsHeaders[index];
      xhr.setRequestHeader(k, opts.headers[k]);
    }

    xhr.onload = (e) => {
      const { responseText } = e.target;

      return resolve({
        ok: true,
        text: () => Promise.resolve(responseText),
        json: () => Promise.resolve(JSON.parse(responseText)),
      });
    };

    xhr.onerror = reject;

    xhr.send(isFormData ? obj : JSON.stringify(obj));
  });
};

const refreshTokenLink = (setLoginState, enqueueSnackbar) => onError(
  (response) => {
    const {
      graphQLErrors, operation, forward,
    } = response;
    if (graphQLErrors) {
      console.log(graphQLErrors)
      const [firstError] = graphQLErrors;

      if (firstError.message === expiredToken) {
        return fromPromise(
          onExpiredToken(API_URL).catch(() => { logout(setLoginState, refreshTokenExpired, enqueueSnackbar) }),
        ).flatMap((values) => {
          const oldHeaders = operation.getContext().headers;
          // modify the operation context with a new token
          operation.setContext({
            headers: {
              ...oldHeaders,
              authorization: `JWT ${values.token}`,
            },
          });

          return forward(operation);
        });
      }
      return undefined;
    }

    return undefined;
  },
);

const client = (setLoginState, enqueueSnackbar) => new ApolloClient({
  link: concat(refreshTokenLink(setLoginState, enqueueSnackbar), createUploadLink({
    uri: API_URL,
    fetch: typeof window === 'undefined' ? global.fetch : customFetch,
  })),
  cache: new InMemoryCache(),
  defaultOptions: {
    query: {
      errorPolicy: 'all',
      fetchPolicy: 'network-only',
    },
    mutate: {
      errorPolicy: 'all',
      fetchPolicy: 'no-cache',
    },
  },
});

const Apollo = (props) => {
  const { enqueueSnackbar } = useSnackbar();
  const {setLoginState, isLogin} = props;

  return (
    <ApolloProvider client={client(setLoginState, enqueueSnackbar)}>
      <LayoutView isLogin={isLogin} setLoginState={setLoginState} />
    </ApolloProvider>
  );
};

export default Apollo;
